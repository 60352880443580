.GuideEnvironmentCloseIconModal {
  --bs-modal-width: 42.756rem !important;
  --bs-modal-height: 34.946rem !important;

  .modalBodayMain {
    border-radius: 1rem;
    background-color: #e8eff3;
    opacity: 1;

    .GuideEnvironmentCloseIcon {
      display: flex;
      justify-content: end;
      margin: 0.625rem 0.625rem 5rem;
    }
    .modalBoday {
      margin: 5rem 1.875rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.125rem;
      img {
        margin: 1.125rem 1.563rem 1.625rem;
      }

      .modalBodayHeader {
        text-align: center;
        font-weight: bold;
        font-size: 1.625rem;
        line-height: 2.25rem;
        font-family: "openSansBold";
        letter-spacing: 0rem;
        color: #03234a;
        margin-top: 1.25rem;
      }

      .modalBodayTitle {
        text-align: center;
        font-size: 1.625rem;
        line-height: 2.25rem;
        font-family: "openSansRegular";
        letter-spacing: 0rem;
        color: #03234a;
        margin-bottom: 3.625rem;
      }

      h4 {
        color: #03234a;
        text-align: center;
        font-weight: bold;
        font-size: 1.625rem;
        line-height: 2.25rem;
        font-family: "openSansBold";
      }

      h6 {
        color: #03234a;
        text-align: center;
        font-size: 1.25rem;
        line-height: 2.25rem;
        font-family: "openSansBold";
      }

      .modalBodayDark {
        letter-spacing: 0rem;
        color: #03234a;
        font-weight: bold;
        text-align: center;
        font-size: 1.625rem;
        line-height: 2.25rem;
        font-family: "openSansBold";
        margin: 0.313rem;
      }

      .modalBodayFooterMessage {
        margin-top: 1.563rem;
      }

      .modalBodayLite {
        letter-spacing: 0rem;
        color: #03234a;
        text-align: center;
        font-size: 1.625rem;
        line-height: 2.25rem;
        font-family: "openSansRegular";
      }
      .modalDoneButton {
        border: none;
        padding: 0;
        margin: 0;
        button {
          width: 10rem;
          height: 3rem;
          background-color: #03234a;
          color: #d0fb56;
        }
      }
    }

    .ConfirmEnvironmentFooter {
      display: flex;
      justify-content: center;
      margin: 1.25rem;

      .ConfirmEnvironmentFooterNo {
        width: 6.875rem;
        height: 2.5rem;
        margin: 0.625rem;
        background: #e8eff3 0% 0% no-repeat padding-box;
        border: 0.063rem solid #03234a;
        border-radius: 0.375rem;
        font-family: "openSansBold";
        letter-spacing: 0rem;
        color: #03234a;
        text-transform: uppercase;
        opacity: 1;
      }

      .ConfirmEnvironmentFooterYes {
        width: 6.875rem;
        height: 2.5rem;
        margin: 0.625rem;
        letter-spacing: 0rem;
        color: #d0fb56;
        text-transform: uppercase;
        background: #03234a 0% 0% no-repeat padding-box;
        border-radius: 0.375rem;
        opacity: 1;
      }
    }
  }
}
