.userAdminPageOuterContainer {
  background: #e8eff3 0% 0% no-repeat padding-box;
  padding: 1.5rem 2rem;
  width: 100%;

  .jobDependencyNotification {
    display: flex;
    justify-content: center;
    align-items: center;

    .endPointSavedMessage {
      position: absolute;
      z-index: 2;
      top: 9.188rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #d0fb5680 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.625rem 0.625rem #00000029;
      border: 0.063rem solid #ffffff;
      border-radius: 0.375rem;
      opacity: 1;
      height: 3rem;

      span {
        font-family: "opensansSemiBold";
        letter-spacing: 0rem;
        color: #03234a;
        img {
          margin: 0.625rem;
        }
      }

      .endPointSavedMessageCrossIcon {
        margin: 0 0.938rem;
        cursor: pointer;
      }
    }
  }

  .datasourcesmaincontainer {
    .userSaveMessage {
      position: absolute;
      z-index: 2;
      left: 43.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #d0fb5680 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.625rem 0.625rem #00000029;
      border: 0.063rem solid #ffffff;
      border-radius: 0.375rem;
      opacity: 1;
      width: 23.25rem;
      height: 3rem;

      .UserSavedMessageIcon {
        margin: 0.625rem;
      }

      .UserSavedMessageBold {
        text-align: left;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.375rem;
        font-family: "opensansBold";
        letter-spacing: 0rem;
        color: #03234a;
      }

      .UserSavedMessageSemiBold {
        margin-left: 0.313rem;
        text-align: left;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.375rem;
        font-family: "opensansSemiBold";
        letter-spacing: 0rem;
        color: #03234a;
      }

      .UserSavedMessageCrossIcon {
        margin-right: 0.938rem;
      }
    }

    .datasourceheader {
      .environmentDetailsHeaderContainer {
        display: flex;
        justify-content: space-between;

        .environmentDetailsHeader {
          margin-top: 0.875rem;
          margin-bottom: 1.25rem;
          text-align: left;
          font-size: 1.75rem;
          line-height: 2.375rem;
          font-weight: 600;
          font-family: "opensansSemiBold";
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1;
        }

        .dataSourceCancleAndSaveButton {
          display: flex;
          margin-top: 0.5rem;
          margin-bottom: 1rem;

          #saveCancelButtonSave {
            width: 10rem;
            height: 3rem;
            color: #d0fb56;
            background-color: #03234a;
            border: 0.063rem solid #03234a;
            border-radius: 0.375rem;
            text-align: center;
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-family: "openSansBold";
            font-weight: bold;
            letter-spacing: 0rem;
            text-transform: uppercase;
          }

          #saveCancelButtonCANCEL {
            width: 10rem;
            height: 3rem;
            color: #03234a;
            background-color: #e8eff3;
            border: 0.063rem solid #03234a;
            margin-right: 1.25rem;
            border-radius: 0.375rem;
            text-align: center;
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-family: "openSansBold";
            font-weight: bold;
            letter-spacing: 0rem;
            text-transform: uppercase;
          }
        }
      }
    }
    #selectedDataSource {
      background: #f7fee2;
    }

    .userListContainer {
      overflow: auto;
      height: 51.875rem;
    }
    .firstseachsidebardatalist {
      .sidebarfilledData {
        margin: 0.625rem 0rem;
        display: flex;
        justify-content: space-between;
        padding: 1rem 2.125rem 1rem 2.5rem;

        .sidebarfilledFirstTitle {
          display: flex;
          margin-right: 0.625rem;
          padding: 0rem !important;

          .sidebarProfileIcon {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .sidebarDataListTitle {
            width: 11.5rem;
          }

          .sidebarlistdata {
            text-align: left;
            font-weight: 600;
            font-size: 1rem;
            word-wrap: break-word;
            line-height: 1.375rem;
            font-family: "openSansSemibold";
            letter-spacing: 0rem;
            color: #03234a;
            opacity: 1 !important;
            margin-left: 0.625rem;
            margin-bottom: 0rem !important;
            padding: 0rem;
          }

          .sidebarlistdataType {
            color: #abb8c3;
            text-align: left;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.063rem;
            font-family: "openSansSemibold";
            letter-spacing: 0rem;
            opacity: 1 !important;
            margin-left: 0.625rem;
            padding: 0rem;
          }
        }

        .sidebarfilledIcons {
          display: flex;
          width: 2.5rem !important;
        }

        .sidebarfilledSecondTitle {
          text-align: left;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.375rem;
          font-family: "openSansSemibold";
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1 !important;
        }
      }
    }

    .SideBarTablesvg {
      padding: 0rem !important;
    }

    .sidebarlistsubdata {
      position: absolute;
      top: 3.125rem;
    }

    .sidebarcloseicon {
      position: absolute;
      margin-left: 1.563rem;
    }

    .firstsidebarsearch {
      margin-left: 0.625rem;
      display: flex;
      width: 100%;
      margin-bottom: 0.938rem;
    }

    .sidebarsearch {
      width: 80%;
      height: 2rem;
      margin: 0.938rem 0 0.938rem 1.875rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.063rem solid #cad3dd;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      padding: 0.313rem 0.313rem 0.313rem 0.75rem;
      padding: 0 0 0 0.75rem;

      .searchBar {
        height: 1.375rem;
        border: none;
        box-shadow: none;
        margin-top: 0.25rem;
      }
    }
  }

  .userAdminPageInputContainer {
    padding: 1.875rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0rem 0.375rem 0.375rem 0.375rem;
    width: 80%;

    .userAdminPageHeaderContainer {
      display: flex;
      justify-content: space-between;
      height: 5%;
      .userAdminPageHeader {
        font: normal normal bold 1.5rem/2.063rem "OpenSansBold";
        color: #03234a;
        margin-left: 0rem;
        .userAdminPageStatusContainer {
          display: flex;
        }
      }
    }

    .ensembleModellingDetailsContainer {
      height: 25%;
    }
    .graphContainer {
      height: 70%;

      .graphContainerHeader {
        height: 7%;
        font: normal normal bold 1.5rem/2.063rem "OpenSansBold";
        color: #03234a;
        display: flex;
        align-items: center;
        margin-top: 1rem;
      }

      .graphContainerBody {
        height: 93%;
      }
    }
  }

  .userEmptyErrorMessage {
    color: #ff4a51;
    font: normal normal bold 0.75rem/1.063rem "OpenSansBold";
    padding: 0.625rem;
  }

  .deleteRowContainer {
    .parameterName {
      width: 50%;
    }

    .parameterValue {
      width: 45%;
    }

    .deleteIconContainer {
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      .deleteIcon {
        margin-left: 1rem;
      }
    }
  }
  .sidebaraddicon {
    position: relative;
    display: inlineblock;
  }

  .PreLoadJobCarousel_Main {
    display: flex;

    .PreLoadJobCarousel_First {
      background-color: #efeeee;
      width: 50%;
      height: 25rem;
      text-align: center;
      justify-content: center;
    }

    .PreLoadJobCarousel_Second {
      background-color: #efeeee;
      width: 50%;
      text-align: center;
      justify-content: center;
    }
  }

  .sidebardatalistIsNotAvailable {
    padding: 6.25rem 0 0 0;
    text-align: center;
  }

  .sidebarheaderSelectIcon {
    .sidebarheadingSelectDD {
      border: none;
      background-color: transparent;
      width: 0rem;
      padding: 0rem 0.313rem 0.5rem 0.313rem;
    }

    .sidebarheadingSelectTooltipsContainer {
      .sidebarheadingTooltipsContainerBody {
        opacity: 1;
        position: relative;
        left: -0.4rem;
        top: 3rem;

        .sidebarheadingTooltipsContainertext_Delete {
          visibility: visible;
          opacity: 1;
          width: 11.5rem;
          height: 3rem;
          padding: 0.75rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.12rem solid #d0fb56;
          text-align: center;
          position: absolute;
          z-index: 1;
          top: -2.5rem;
          bottom: 5.31rem;
          left: -8rem;
          transition: opacity 0.3s;
          font-size: 1.1rem;
          line-height: 1.68rem;
          font-family: "openSansRegular";
          letter-spacing: 0rem;
          color: #666666;
          border-bottom: 0.063rem solid lightgray;
        }
        .sidebarheadingTooltipsContainertext_ResendIcon {
          visibility: visible;
          opacity: 1;
          width: 11.5rem;
          height: 3rem;
          padding: 0.75rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 0.12rem solid #d0fb56;
          border-top: none;
          text-align: center;
          position: absolute;
          z-index: 1;
          top: 0.5rem;
          bottom: 5.31rem;
          left: -8rem;
          transition: opacity 0.3s;
          font-size: 1.1rem;
          line-height: 1.68rem;
          font-family: "openSansRegular";
          letter-spacing: 0rem;
          color: #666666;
        }

        .sidebarheadingTooltipsContainertext_Delete::after {
          content: "";
          position: absolute;
          pointer-events: none;
          border: 0.12rem solid #d0fb56;
          bottom: 100%;
          left: 74.5%;
          transform: rotate(180deg);
          margin-left: -0.31rem;
          border-width: 0.93rem;
          border-style: solid;
          border-color: #d0fb56 transparent transparent transparent;
        }
      }
    }

    .sidebarheadingMenuSelect {
      box-shadow: 0rem 0.625rem 1.25rem #0000001a;
      opacity: 1;
      .sidebarheadingMenuItemSelect {
        padding: 0.625rem !important;
        width: auto;
        text-align: left;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.375rem;
        font-family: "openSansBold";
        letter-spacing: 0rem;
        color: #03234a;
        opacity: 1;
        margin: 0rem 0.625rem;
      }
    }
  }
}

#userAdminPageTabContainer {
  width: 111.25rem;
  height: 48.313rem;
  background-color: #ffffff;
  --bs-nav-link-color: #03234a !important;
  font: normal normal bold 1.5rem/2.063rem "OpenSansBold";
}

.userAdminPageMainContainer {
  display: flex;
  .SideBarBackIconContenar {
    background-color: #ffffff;
    width: 0rem;
    position: relative;
    .SideBarBackIcon {
      margin-top: 21.875rem;
      margin-left: -0.938rem;
      z-index: 2;
    }
  }

  .TooltipsContainer {
    .TooltipsContainerBody {
      opacity: 1;
      position: relative;

      .TooltipsContainertext {
        visibility: visible;
        opacity: 1;
        width: 15rem;
        height: 3rem;
        padding: 0.75rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.12rem solid #d0fb56;
        text-align: center;
        border-radius: 0.37rem;
        position: absolute;
        z-index: 1;
        top: -0.5rem;
        bottom: 5.31rem;
        left: 8.7rem;
        transition: opacity 0.3s;
        font-size: 1rem;
        line-height: 1.375rem;
        font-family: "openSansBold";
        letter-spacing: 0rem;
        color: #03234a;

        .TooltipsContainertextDark {
          text-align: left;
          font-size: 1.25rem;
          line-height: 1.68rem;
          font-family: "openSansBold";
          letter-spacing: 0rem;
          color: #03234a;
        }

        .TooltipsContainertextLite {
          span {
            margin-right: 0.31rem;
            text-align: left;
            text-decoration: underline;
            font-size: 1.25rem;
            line-height: 1.68rem;
            font-family: "openSansRegular";
            letter-spacing: 0rem;
            color: #03234a;
          }

          text-align: left;
          font-size: 1.25rem;
          line-height: 1.68rem;
          font-family: "openSansRegular";
          letter-spacing: 0rem;
          color: #666666;
        }
      }

      .TooltipsContainertext::after {
        content: "";
        position: absolute;
        pointer-events: none;
        border: 0.12rem solid #d0fb56;
        bottom: 100%;
        left: 50%;
        transform: rotate(180deg);
        margin-left: -0.31rem;
        border-width: 0.93rem;
        border-style: solid;
        border-color: #d0fb56 transparent transparent transparent;
      }
    }
  }

  .datasourcefirstsidebarcontainer {
    border-right: 0.063rem solid #abb8c3;
    background-color: #ffffff;
    width: 20%;
    min-width: 20rem;
    padding: 0.625rem 0rem;

    .sidebarheader {
      display: flex;
      justify-content: space-between;
      margin: 0.625rem;
      padding: 0.313rem 0rem 0rem 1.438rem;

      .sidebarheading {
        text-align: left;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-family: "openSansSemibold";
        letter-spacing: 0rem;
        color: #03234a;
        opacity: 1 !important;
      }

      .sidebarheaderRightIcon {
        display: flex;
        padding-right: 1.9rem;

        .sidebarheadingDD {
          border: none;
          background-color: #ffffff;
          width: 2.5rem;
          margin: 0rem 0.313rem;
        }

        .sidebarheadingMenu {
          border: 0.125rem solid #d0fb56;
          box-shadow: 0rem 0.625rem 1.25rem #0000001a;
          opacity: 1;
          margin-left: -9.375rem;

          .sidebarheadingMenuItem {
            padding: 0.313rem !important;
            width: auto;
            text-align: left;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.375rem;
            font-family: "openSansBold";
            letter-spacing: 0rem;
            color: #03234a;
            opacity: 1;
            margin: 0rem 0.625rem;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0.313rem;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #d3e2e7;
}

.inputSwitchContainer {
  display: flex;
  margin-top: 1.25rem;
}

.switchButton {
  .switchButtonInput input {
    width: 2rem;
    height: 1.25rem;
  }

  .switchButtonInput input[type="checkbox"]:checked {
    background-color: #03234a;
    border-color: #03234a;
  }
  .switchButtonInput input[type="checkbox"]:hover {
    outline: none !important;
  }
  .switchBottonLabelActive {
    margin: 0.3rem 2rem 0rem 0.1rem;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    font-family: "openSansRegular" !important;
    letter-spacing: 0rem;
    color: #03234a;
    opacity: 1;
    display: flex;
  }
  .switchBottonLabelInActive {
    margin: 0.3rem 2rem 0rem 0.1rem;
    font-size: 1rem !important;
    line-height: 1.375rem !important;
    font-family: "openSansRegular" !important;
    letter-spacing: 0rem;
    color: #03234a;
    opacity: 1;
    display: flex;
  }
}

.customPluginInputContainer {
  width: 80%;
  .typeDropdown {
    width: 50%;

    .recurrenceFrequencyDropdown {
      width: 85%;
      min-width: 5.5rem;
      height: 3rem;
    }
  }

  .formLabel {
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
    text-align: left;
    font: normal normal normal 1rem/1.375rem opensansRegular;
    letter-spacing: 0rem;
    color: #03234a;
  }

  .descriptionContainer {
    width: 100%;
  }

  #inputContainerOne {
    display: flex;
    width: 80%;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;

    .nameInput {
      width: 50%;
    }
    .tagInputContainer:first-child {
      width: 50%;
    }
    .tagInputContainer {
      width: 50%;
    }
    .deleteIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .deleteIcon {
        margin-left: 1rem;
      }
    }

    .inputContainerInputGroup {
      width: 26.25rem;

      Button {
        border: 0rem;
        height: 2.438rem;
        background-color: #e8eff3;
      }
    }

    .inputLabelSelectorContainer {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 50%;
    }

    .assignAdditionalRoleText {
      text-decoration: underline;
      font-size: 1rem;
      line-height: 1.375rem;
      font-family: "OpenSansSemiBold";
      letter-spacing: 0rem;
      color: #03234a;
      opacity: 1;
    }
  }
}
