.supportMainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2rem;
  row-gap: 0.75rem;
}

.supportText {
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: "openSansBold";
  letter-spacing: 0rem;
  color: #03234a;
  text-align: center;
}

.supportEmail {
  text-decoration: none;
  font-size: 2rem;
  line-height: 2.5rem;
  font-family: "openSansRegular";
  letter-spacing: 0rem;
  color: #03234a;
  overflow-wrap: break-word;
  text-align: center;
}

@media (min-width: 1536px) {
  .supportMainContainer {
    flex-direction: row;
  }

  .supportText,
  .supportEmail {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
