.GuideEnvironmentCloseIconModal {
  --bs-modal-width: 42.756rem !important;
  --bs-modal-height: 34.946rem !important;

  .modalBodayMain {
    border-radius: 1rem;
    background-color: #e8eff3;
    opacity: 1;

    .GuideEnvironmentCloseIcon {
      display: flex;
      justify-content: end;
      margin: 0.625rem 0.625rem 5rem;
    }
    .modalBoday {
      margin: 5rem 1.875rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.125rem;
      img {
        margin: 1.125rem 1.563rem 1.625rem;
      }

      .modalBodayHeader {
        text-align: center;
        font: normal normal 600 1.625rem/2.25rem "OpenSansSemibold";
        letter-spacing: 0rem;
        color: #03234a;
        margin: 2.438rem 8.125rem;
      }
    }
  }
}
