@import "_general.scss";

.custom-job-management-tooltip .tooltip-inner {
  width: 253px;
  max-width: 253px;
  height: 195px;
  position: relative;
  top: -2px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px #0000001a;
  border: 2px solid #d0fb56;
  border-radius: 10px;
  z-index: 1;
}

.custom-job-management-tooltip .tooltip-arrow::before {
  bottom: 2px;
  left: -7px;
  border-width: 14px;
  border-bottom-color: #d0fb56;
}

.app-container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

.logo-header {
  display: flex;
  height: 4rem;
}

.sidebar-app-container {
  display: flex;
  height: calc(100vh - 4rem);
}

.sidebar-container {
  min-width: 300px;
  height: 100%;
}

.routes-container {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}