.resetPageContainer {
  background-color: #e8eff3;
  display: flex;
  width: 100%;
  @media screen and (min-width: 120rem) {
    height: 100%;
  }
  .resetPageLeftPanel {
    margin-top: 0rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../Icons/LoginPageLeftPanelBackground.png");
    background-size: cover;
    background-repeat: round;
    background-color: transparent;
    padding-top: 8%;
    padding-right: 7%;
  }
  #wadeLogoIconId {
    width: 15.25rem;
    height: 2.625rem;
  }
  #resetLeftIconId {
    margin-bottom: 5.375rem;
  }
  .resetPageMainHeading {
    font: normal normal bold 2.5rem/3.438rem "OpenSansBold";
    margin-bottom: 2.4375rem;
    color: #fff;
    width: 38rem;
    height: 6.875rem;
  }
  .resetPageSubHeading {
    font: normal normal normal 1.25rem/1.688rem "OpenSansRegular";
    width: 38rem;
    height: 5.0625rem;
    color: #fff;
    text-align: left;
  }
  .resetPageRightPanel {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 8%;
  }
  #resetPageLoginHeading {
    margin: 4.28rem 0rem 4.06rem 0rem;
    font: normal normal 600 2rem/2.688rem "OpenSansSemiBold";
    color: #03234a;
  }
  .resetPasswordInputContainer {
    margin-bottom: 1.5rem;
  }
  #resetPageInput {
    width: 25rem;
    height: 3rem;
    border-radius: 0.375rem;
  }
  #resetPasswordWrongInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
    color: #e88181;
    border: 0.063rem solid #e88181;
    &:focus {
      outline: none !important;
      border: 0.063rem solid #e88181 !important;
    }
  }
  #resetPageLabelInput {
    color: #03234a;
    white-space: nowrap;
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    margin-bottom: 0.75rem;
  }
  .resetPasswordInvalidText {
    width: 25rem;
    font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
    color: #e88181;
    padding-top: 0.31rem;
  }
  #resetPageNewPasswordProceedButton {
    width: 25rem;
    height: 3rem;
    line-height: 1.25rem;
    margin-top: 2%;
    background-color: #03234a;
    font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
    color: #d0fb56;
    margin-left: 0rem;
  }
  #resetCopyRightText {
    right: 0;
    bottom: 0;
    margin: 30% 0rem 1% 0rem;
    font: normal normal normal 0.75rem/1.063rem "OpenSansRegular";
    color: #03234a;
    #innerCopyRightText {
      margin: 0rem 1.25rem 0rem 1.25rem;
    }
  }
  #resetCopyRightText {
    a {
      text-decoration: none;
      color: #03234a;
    }
  }
}
