.GuideEnvironmentCloseIconModal {
  --bs-modal-width: 42.756rem !important;
  --bs-modal-height: 34.946rem !important;

  .modalBodayMain {
    border-radius: 1rem;
    background-color: #ffffff;
    opacity: 1;

    .GuideEnvironmentCloseIcon {
      display: flex;
      justify-content: end;
      margin: 0.625rem 0.625rem 5rem;
    }
    .modalBoday {
      margin: 5rem 1.75rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.125rem;
      img {
        margin: 1.125rem 1.563rem 1.625rem;
      }
      .modalDoneButton {
        border: none;
        padding: 0;
        margin: 0;
        button {
          width: 10rem;
          height: 3rem;
          background-color: #03234a;
          color: #d0fb56;
          font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
        }
      }
    }
    .modalBodayHeader {
      text-align: center;
      font: normal normal 600 1.625rem/2.25rem "OpenSansSemibold";
      letter-spacing: 0rem;
      color: #03234a;
      margin: 1.813rem;
    }
  }
}
