.modalPopUpCodeVerification {
  --bs-modal-width: 56.44rem !important;
  --bs-modal-height: 55.125rem !important;
  padding: 0 !important;
  .codeVerificationMain {
    .codeVerificationMainBody {
      background-color: #ffffff;
      padding: 10.59rem 6.8rem;
      border-radius: 0.37rem;
      opacity: 1;

      .codeVerificationBodyHeadWade {
        h6 {
          color: #03234a;
          font: normal normal 600 0.93rem/1.37rem Open Sans;
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1;
        }
      }
      .codeVerificationBodyHeadEmailVerification {
        p {
          color: #03234a;
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1;
          padding: 4.16rem 0rem 4.5rem 0rem;
          margin: 0;
          font-family: "openSansSemiBold";
          line-height: 2.68rem;
          font-size: 2rem;
        }
      }
      .codeVerificationBodyHeadCodeNote {
        p {
          font: normal normal 600 1rem/1.375rem "OpenSansSemiBold";
          color: #03234a;
          text-align: center;
          letter-spacing: 0rem;
          opacity: 1;
          margin: 0;
        }
      }
      .codeVerificationBodyOptionEmail {
        .authenticatorBodyEmail {
          input {
            width: 15.625rem;
          }
          .invalidMessageDisplay {
            color: #e03535;
            font: normal normal bold 0.62rem/0.62rem "OpenSansBold";
            padding: 0.62rem;
          }
        }

        .codeVerificationBodyCode {
          .codeVerificationBodyEmailInput {
            input {
              margin: 0.62rem;
              width: 4rem;
              height: 4rem;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 0.063rem solid #cad3dd;
              border-radius: 0.37rem;
              margin: 2.5rem 1.5rem 3.5rem 1.5rem;
              padding: 1.563rem;
              font: normal normal 600 1.12rem/1.5rem "OpenSansSemiBold";
            }
          }
        }
      }

      .invalidOTPMessage {
        font: normal normal normal 0.87rem/1.18rem "OpenSansRegular";
        color: #e88181;
        padding: 1.25rem;
        padding-top: 0rem;
      }

      .codeVerificationBodyBTNBody {
        .codeVerificationBodyBTN {
          color: #d0fb56;
          background-color: #03234a;
          width: 25rem;
          height: 3rem;
          margin-bottom: 3.62rem;
          font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
          cursor: pointer;
        }
      }
      .codeVerificationResendCode {
        p {
          text-decoration: underline;
          letter-spacing: 0rem;
          color: #03234a;
          font: normal normal 600 1rem/1.375rem "OpenSansSemiBold";
          opacity: 1;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }
}
