.forgotPageContainer {
  width: 100%;
  background-color: #e8eff3;
  display: flex;
  @media screen and (min-width: 120rem) {
    height: 100%;
  }
  .forgotPageLeftPanel {
    margin-top: 0rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../Icons/LoginPageLeftPanelBackground.png");
    background-size: cover;
    background-repeat: round;
    background-color: transparent;
    padding-right: 7%;
    padding-top: 7%;
  }
  #forgotLeftIconId {
    margin-bottom: 5.37rem;
  }
  .forgotPageMainHeading {
    font: normal normal bold 2.5rem/3.438rem "OpenSansBold";
    margin-bottom: 2.43rem;
    color: #fff;
    width: 38rem;
    height: 6.87rem;
  }
  .forgotPageSubHeading {
    font: normal normal normal 1.25rem/1.688rem "OpenSansRegular";
    width: 38rem;
    height: 5.06rem;
    color: #fff;
    text-align: left;
  }
  .forgotPageRightPanel {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 10%;
    .forgotPagePasswordResetText {
      margin-top: 1.56rem;
      font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
      color: #03234a;
    }
  }
  #wadeLogoIconId {
    width: 15.25rem;
    height: 2.62rem;
  }
  #forgotPageLoginHeading {
    margin-top: 4.28rem;
    margin-bottom: 4.06rem;
    font: normal normal 600 2rem/2.688rem "OpenSansSemiBold";
    color: #03234a;
  }
  .userIdContainer {
    padding-top: 2.56rem;
  }
  #forgotPageEmailInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
  }
  #forgotPageLabelInput {
    color: #03234a;
    white-space: nowrap;
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    margin: 0rem 0rem 0.75rem 0rem;
  }
  .forgotPagePasswordResetText {
    margin-top: 1.56rem;
    font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
    color: #03234a;
    width: 25rem;
  }
  #forgotPageNewUserIdProceedButton {
    width: 25rem;
    height: 3rem;
    line-height: 1.25rem;
    margin: 10% 0rem 17% 0rem;
    background-color: #03234a;
    font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
    color: #d0fb56;
    margin-left: 0rem;
  }
  #forgotCopyRightText {
    right: 0;
    bottom: 0;
    font: normal normal normal 0.75rem/1.063rem "OpenSansRegular";
    color: #03234a;
    margin-bottom: 2.62rem;
    #innerCopyRightText {
      margin: 0rem 1.25rem 0rem 1.25rem;
    }
  }

  #forgotCopyRightText {
    a {
      text-decoration: none;
      color: #03234a;
    }
  }

  .forgotPasswordInvalidText {
    width: 25rem;
    font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
    color: #e88181;
    padding-top: 0.31rem;
  }

  #forgotPasswordWrongInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
    color: #e88181;
    border: 0.063rem solid #e88181;
  }
}
