.authenticatorMain {
  --bs-modal-width: 56.45rem !important;
  --bs-modal-height: 55rem !important;
  .authenticatorMainBody {
    background-color: #ffffff;
    margin-top: 3.75rem;
    border-radius: 0.375rem;
    padding: 4.5rem 6.1rem;
    opacity: 1;

    .authenticatorBodyHead {
      p {
        color: #03234a;
        font: normal normal 600 2rem/2.688rem "OpenSansSemiBold";
        letter-spacing: 0rem;
        color: #03234a;
        margin: 4.164rem 0rem 5.125rem 0rem;
        opacity: 1;
        width: 42rem;
      }
      .authenticatorBodySubtitle {
        color: #03234a;
        font: normal normal 600 1.37rem/2rem "OpenSansSemiBold";
        letter-spacing: 0rem;
        color: #03234a;
        opacity: 1;
      }
    }
    .authenticatoRightIcon {
      height: 1.37rem;
      width: 1.97rem;
      margin-bottom: 0.62rem;
    }
    .authenticatorBodyOption {
      padding: 0.62rem 0rem;
      width: 38.68rem;
      .authenticatorBodyLPMicrosoft {
        padding: 1rem 1.31rem;
        margin: 0.31rem 0.62rem;
        width: 4rem;
        height: 4rem;
        border: 0.063rem solid #abb8c3;
        border-radius: 0.375rem;
        opacity: 1;
        .authenticatorLeftIcons {
          width: 1.37rem;
          height: 1.37rem;
        }
      }
      .authenticatorBodyLPEmail {
        padding: 1rem 1.09rem;
        margin: 0.31rem 0.62rem;
        width: 4rem;
        height: 4rem;
        border: 0.063rem solid #abb8c3;
        border-radius: 0.375rem;
        opacity: 1;
        .authenticatorLeftIcons {
          width: 1.81rem;
          height: 1.37rem;
        }
      }
      .authenticatorBodyLPPhone {
        padding: 1.09rem 1.45rem;
        margin: 0.31rem 0.62rem;
        width: 4rem;
        height: 4rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.063rem solid #abb8c3;
        border-radius: 0.375rem;
        opacity: 1;
        .authenticatorLeftIcons {
          width: 0.97rem;
          height: 1.55rem;
          opacity: 0.5 !important;
        }
      }

      .authenticatorBodyMP {
        width: 38.68rem;
        padding-bottom: 0.62rem;
        border-bottom: 0.063rem solid #e8eff3;

        h6 {
          margin: 0rem;
          font: normal normal 600 1.5rem/2.06rem "OpenSansSemiBold";
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1;
        }

        span {
          font: normal normal 600 1rem/1.37rem "OpenSansSemiBold";
          letter-spacing: 0rem;
          color: #7c7c7c;
          opacity: 1;
        }
      }
      .authenticatorBodyMPPhone {
        width: 38.68rem;
        padding-bottom: 0.62rem;
        border-bottom: 0.063rem solid #e8eff3;
        opacity: 0.3;

        h6 {
          margin: 0rem;
          font: normal normal 600 1.5rem/2.06rem "OpenSansSemiBold";
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1;
        }

        span {
          font: normal normal 600 1rem/1.37rem "OpenSansSemiBold";
          letter-spacing: 0rem;
          color: #7c7c7c;
          opacity: 1;
        }
      }
    }

    .authenticatorBodyBTNBody {
      margin-top: 5.18rem;
      .authenticatorBodyBTN {
        color: #d0fb56;
        background-color: #03234a;
        width: 25rem;
        font: normal normal bold 1.12rem/1.5rem "OpenSansBold";
        height: 3rem;
      }
    }
  }
}
