.react-flow__edge {
  pointer-events: visibleStroke;
  cursor: pointer;
}

.react-flow__edge-path,
.react-flow__connection-path {
  stroke: #555;
  stroke-width: 2;
  fill: none;
}

.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: red;
}

.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus,
.react-flow__node-default.selectable:focus-visible,
.react-flow__node-input.selectable.selected,
.react-flow__node-input.selectable:focus,
.react-flow__node-input.selectable:focus-visible,
.react-flow__node-output.selectable.selected,
.react-flow__node-output.selectable:focus,
.react-flow__node-output.selectable:focus-visible,
.react-flow__node-group.selectable.selected,
.react-flow__node-group.selectable:focus,
.react-flow__node-group.selectable:focus-visible {
  box-shadow: 0 0 0 0.031rem red;
}

.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  padding: 0.625rem;
  border-radius: 0.219rem;
  width: 9.375rem;
  font-size: 0.75rem;
  color: #222;
  text-align: center;
  border-width: 0.188rem;
  border-style: solid;
  border-color: #141414;
  background-color: white;
}

.react-flow__node-default.selectable:hover,
.react-flow__node-input.selectable:hover,
.react-flow__node-output.selectable:hover,
.react-flow__node-group.selectable:hover {
  box-shadow: 0 0.063rem 0.25rem 0.063rem red;
  border-width: 0.219rem;
  border-style: solid;
  border-color: red;
  background-color: white;
}
.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus,
.react-flow__node-default.selectable:focus-visible,
.react-flow__node-input.selectable.selected,
.react-flow__node-input.selectable:focus,
.react-flow__node-input.selectable:focus-visible,
.react-flow__node-output.selectable.selected,
.react-flow__node-output.selectable:focus,
.react-flow__node-output.selectable:focus-visible,
.react-flow__node-group.selectable.selected,
.react-flow__node-group.selectable:focus,
.react-flow__node-group.selectable:focus-visible {
  box-shadow: 0 0 0 0.031rem red;
  border-width: 0.219rem;
  border-style: solid;
  border-color: red;
  background-color: white;
}

.react-flow__attribution {
  font-size: 0rem !important ;
  background: rgba(255, 255, 255, 0.5);
  padding: 0rem 0rem;
  margin: 0;
}