.BreadcrumbContainer {
  .Breadcrumb {
    height: 40px;
    .BreadcrumbItem::before {
      display: none !important;
    }
    .BreadcrumbItem {
      margin-right: 8px;
      padding: 0;
      border: 1px solid transparent;
      a {
        text-decoration: none;
        text-align: left;
        font: normal normal 600 1rem/1.375rem "OpenSansSemiBold";
        letter-spacing: 0rem;
        color: #03234a;
        padding: 0.25rem 0.5rem;
        display: inline-block;
        transition: background-color 0.3s, color 0.3s;
      }

      &.active {
        border: 1px solid #c4c4c4;
        background-color: #ffffff;
        color: #03234a !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 0;
      }

      &:hover {
        color: #03234a !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #f9f9f9;
      }
    }
  }

  hr {
    opacity: 0.28;
    margin: 0;
  }
}
