.jobMonitorContainer {
  width: 100%;
  height: 48.125rem;
  display: flex;
  flex-direction: column;
  background-color: white;

  .dataQualityContainerHeader {
    background-color: #e8eff3;
    display: flex;
    width: 100%;
    height: 6.5%;

    .jobDetailsContainerHeader {
      width: 15%;
      border-radius: 0.375rem 0rem 0rem 0rem;
      background: #cad3dd 0% 0% no-repeat padding-box;
      font: normal normal 600 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .jobTaskContainerHeader {
      width: 15%;
      background: #cad3dd 0% 0% no-repeat padding-box;
      font: normal normal normal 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .selectedJobContainer {
      width: 15%;
      position: relative;
      color: #03234a;
      background: #ffffff 0% 0% no-repeat padding-box;
    }

    .selectedJobContainer::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background: #d0fb56 0% 0% no-repeat padding-box;
    }

    .backButton {
      width: 70%;
      font: normal normal normal 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #293d57;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;

      .dashboardLink {
        cursor: pointer;
      }
    }
  }

  .jobExecutionContainer {
    width: 100%;
    height: 93.5%;
    display: flex;

    .jobMonitorGraphContainer {
      min-width: 70%;
      height: 100%;
      border: 0.063rem solid #5c7b8d;
      border-radius: 0.375rem;
      background-color: #f6f9fa;
      margin-right: 1rem;

      .jobMonitorGraphContainerHeader {
        height: 6%;
        width: 100%;
        display: flex;

        .orchestrationHeaderHeading {
          width: 90%;
          background-color: #5c7b8d;
          font: normal normal bold 1rem/1.375rem opensansBold;
          letter-spacing: 0rem;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .refreshIcon {
          width: 10%;
          background-color: #5c7b8d;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .jobMonitorGraphContainerStatus {
        height: 6%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 1rem;

        .jobMonitorStatusItem {
          width: 100%;
          font: normal normal bold 1rem/1.375rem opensansSemiBold;
          letter-spacing: 0rem;
          color: #293d57;
          margin-top: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .jobMonitorStatusColorRed {
            background-color: #f80000;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 60%;
            margin-right: 1rem;
          }
          .jobMonitorStatusColorYellow {
            background-color: #f7ba00;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50%;
            margin-right: 1rem;
          }
          .jobMonitorStatusColorGreen {
            background-color: #119f33;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50%;
            margin-right: 1rem;
          }
          .jobMonitorStatusColorGrey {
            background-color: grey;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50%;
            margin-right: 1rem;
          }
          .jobMonitorStatusColorBlue {
            background-color: #0000ff;
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 50%;
            margin-right: 1rem;
          }
        }
      }

      .jobMonitorGraphContainerContent {
        height: 84%;
        width: 100%;

        .chartArea {
          width: 100%;
          height: 100%;
          overflow-x: scroll;
          overflow-y: auto;

          .noData {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font: normal normal bold 1rem/1.375rem opensansSemiBold;
          }
        }
      }

      .jobMonitorGraphContainerFooter {
        height: 4%;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 1rem;

        .jobTask {
          margin-left: 2rem;
        }
      }
    }

    .jobExecutionFilterContainer {
      min-width: 30%;
      height: 100%;
      border: 0.063rem solid #5c7b8d;
      border-radius: 0.375rem;
      background-color: #f6f9fa;

      .jobMonitorFilterContainerHeader {
        height: 6%;
        width: 100%;
        background-color: #5c7b8d;
        font: normal normal bold 1rem/1.375rem opensansBold;
        letter-spacing: 0rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .jobMonitorFilterContainerContent {
        height: 94%;
        width: 100%;

        .clearFilter {
          height: 6%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .filterText {
            margin-right: 2rem;
            text-decoration: underline;
            font: normal normal bold 1rem/1.375rem opensansSemiBold;
            letter-spacing: 0rem;
            color: blue;
            cursor: pointer;
          }
        }

        .dateMessage {
          height: 4%;
          width: 100%;
          font: normal normal normal 0.95rem/1.375rem opensansSemiBold;
          letter-spacing: 0rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dateContainer {
          width: 100%;
          height: 24%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 0.5rem;

          .dataPickerTab {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 1rem;

            .formLabel {
              display: inline-block;
              width: 20%;
              font: normal normal normal 1rem/1.375rem opensansSemiBold;
              letter-spacing: 0rem;
              color: #03234a;
              margin-right: 0.5rem;
            }

            .datePicker {
              display: inline-block;
              width: 50%;
              max-width: 16.019rem;
              min-width: 9rem;
              height: 3rem;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 0.063rem solid #cad3dd;
              border-radius: 0.375rem;
            }
          }
        }

        .accordionContainer {
          border: 0.063rem solid #dbe2e6;
          border-radius: 0.375rem;
          height: 66%;
          background-color: #f6f9fa;

          .accordion {
            height: 100%;
            padding: 0 1.875rem 1.25rem 1.875rem;
            overflow-y: auto;

            .customAccordionContainer {
              .customAccordion {
                --bs-accordion-border-width: 0rem;
                --bs-accordion-bg: #f6f9fa;
                --bs-accordion-active-bg: #f6f9fa;
                min-height: 4.688rem;

                .customCard {
                  border: none;

                  .accordionItemHeader {
                    height: 4.688rem;
                    display: flex;

                    .checkboxContainer {
                      display: inline-block;
                      position: relative;
                      padding-left: 1.562rem;
                      cursor: pointer;

                      .checkmark {
                        position: absolute;
                        top: 0.063rem;
                        left: 0;
                        height: 1.25rem;
                        width: 1.25rem;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border: 0.0625rem solid #03234a;
                        border-radius: 0.25rem;
                        opacity: 1;
                      }

                      .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                      }

                      .checkmarkLable {
                        margin-left: 0.5rem;
                        text-align: left;
                        font-size: 1rem;
                        line-height: 1.375rem;
                        font-family: "OpenSansSemiBold";
                        letter-spacing: 0rem;
                        color: #03234a;
                        opacity: 1;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        word-break: break-word;
                      }

                      input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                      }
                    }

                    .checkboxContainer input:checked ~ .checkmark:after {
                      display: block;
                    }

                    .checkboxContainer .checkmark:after {
                      left: 0.375rem;
                      top: 0.125rem;
                      width: 0.3125rem;
                      height: 0.625rem;
                      border: 0.062rem solid #03234a;
                      border-width: 0 0.125rem 0.125rem 0;
                      transform: rotate(45deg);
                    }

                    .accordionItemSubType {
                      display: flex;
                      flex-direction: column;
                    }

                    .accordionHeaderContainer {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-right: 2rem;

                      .checkboxContainer {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.562rem;
                        cursor: pointer;

                        .checkmark {
                          position: absolute;
                          top: 0.063rem;
                          left: 0;
                          height: 1.25rem;
                          width: 1.25rem;
                          background: #ffffff 0% 0% no-repeat padding-box;
                          border: 0.0625rem solid #03234a;
                          border-radius: 0.25rem;
                          opacity: 1;
                        }

                        .checkmark:after {
                          content: "";
                          position: absolute;
                          display: none;
                        }

                        .checkmarkLable {
                          margin-left: 0.5rem;
                          text-align: left;
                          font-size: 1rem;
                          line-height: 1.375rem;
                          font-family: "OpenSansSemiBold";
                          letter-spacing: 0rem;
                          color: #03234a;
                          opacity: 1;
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        }

                        input {
                          position: absolute;
                          opacity: 0;
                          cursor: pointer;
                        }
                      }

                      .checkboxContainer input:checked ~ .checkmark:after {
                        display: block;
                      }

                      .checkboxContainer .checkmark:after {
                        left: 0.375rem;
                        top: 0.125rem;
                        width: 0.3125rem;
                        height: 0.625rem;
                        border: 0.062rem solid #03234a;
                        border-width: 0 0.125rem 0.125rem 0;
                        transform: rotate(45deg);
                      }

                      .customSwitch input {
                        height: 1.25rem;
                        width: 2rem;
                      }

                      .customSwitch input[type="checkbox"]:checked {
                        background-color: #03234a;
                        border-color: #03234a;
                      }
                    }

                    .sidebarfilledSubTitle {
                      margin-left: 2.313rem;
                      font: normal normal normal 0.875rem/1.188rem
                        "OpenSansRegular";
                      color: #333333;
                      position: relative;
                      display: flex;
                      align-items: center;
                    }
                  }

                  .accordionBody {
                    background-color: #fbfdfd;
                    padding: 1.125rem 2.188rem;
                    border-top: 0.063rem solid #cad3dd;

                    .accordionBodyElementsContainer {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-top: 1rem;

                      .checkboxContainer {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.562rem;
                        cursor: pointer;

                        .checkmark {
                          position: absolute;
                          top: 0.188rem;
                          left: 0;
                          height: 1.25rem;
                          width: 1.25rem;
                          background: #ffffff 0% 0% no-repeat padding-box;
                          border: 0.0625rem solid #03234a;
                          border-radius: 0.25rem;
                          opacity: 1;
                        }

                        .checkmark:after {
                          content: "";
                          position: absolute;
                          display: none;
                        }

                        .checkmarkLable {
                          margin-left: 0.5rem;
                          text-align: left;
                          font-size: 1rem;
                          line-height: 1.375rem;
                          font-family: "OpenSansSemiBold";
                          letter-spacing: 0rem;
                          color: #03234a;
                          opacity: 1;
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        }

                        input {
                          position: absolute;
                          opacity: 0;
                          cursor: pointer;
                        }
                      }

                      .checkboxContainer input:checked ~ .checkmark:after {
                        display: block;
                      }

                      .checkboxContainer .checkmark:after {
                        left: 0.375rem;
                        top: 0.125rem;
                        width: 0.3125rem;
                        height: 0.625rem;
                        border: 0.062rem solid #03234a;
                        border-width: 0 0.125rem 0.125rem 0;
                        transform: rotate(45deg);
                      }

                      .customSwitch input {
                        height: 1.25rem;
                        width: 2rem;
                      }

                      .customSwitch input[type="checkbox"]:checked {
                        background-color: #03234a;
                        border-color: #03234a;
                      }

                      .accordionBodyElements {
                        text-align: left;
                        font: normal normal 600 1rem/1.375rem opensansSemiBold;
                        letter-spacing: 0rem;
                        color: #03234a;
                      }
                    }
                  }
                }

                .customCard button {
                  text-align: left;
                  font: normal normal 600 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: #03234a;
                  padding: 1rem 0 1rem 0;
                }

                .customCard button:focus {
                  box-shadow: none;
                }
              }
            }

            .accordionLine {
              width: 100%;
              height: 0rem;
              border: 0.063rem solid #cad3dd;
            }

            .accordionBodyList {
              padding: 0.625rem;
            }
          }
        }
      }
    }
  }

  .jobMonitorRightBox {
    width: 100%;
    height: 93.5%;
    display: flex;

    .orchestrationLineChartContainer {
      height: 100%;
      width: 70%;
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
      border: 0.063rem solid #5c7b8d;
      border-radius: 0.375rem;
      background-color: #f6f9fa;
      margin-right: 1rem;

      .jobMonitorGraphContainerHeader {
        height: 6%;
        width: 100%;
        display: flex;

        .orchestrationHeaderHeading {
          width: 90%;
          background-color: #5c7b8d;
          font: normal normal bold 1rem/1.375rem opensansBold;
          letter-spacing: 0rem;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .refreshIcon {
          width: 10%;
          background-color: #5c7b8d;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .orchestrationLineChart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .noData {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal bold 1rem/1.375rem opensansSemiBold;
      }

      .noDataFound {
        font: normal normal normal 1rem/1.375rem opensansSemiBold;
        color: #03234a;
      }
    }

    .jobMonitorFilterContainer {
      width: 30%;
      height: 100%;
      border: 0.063rem solid #5c7b8d;
      border-radius: 0.375rem;
      background-color: #f6f9fa;

      .jobMonitorFilterContainerHeader {
        height: 6%;
        width: 100%;
        background-color: #5c7b8d;
        font: normal normal bold 1rem/1.375rem opensansBold;
        letter-spacing: 0rem;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .jobMonitorFilterContainerContent {
        height: 94%;
        width: 100%;

        .clearFilter {
          height: 6%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .filterText {
            margin-right: 2rem;
            text-decoration: underline;
            font: normal normal bold 1rem/1.375rem opensansSemiBold;
            letter-spacing: 0rem;
            color: blue;
            cursor: pointer;
          }
        }

        .dateMessage {
          height: 4%;
          width: 100%;
          font: normal normal normal 0.95rem/1.375rem opensansSemiBold;
          letter-spacing: 0rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dateContainer {
          width: 100%;
          height: 24%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 0.5rem;

          .dataPickerTab {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 1rem;

            .formLabel {
              display: inline-block;
              width: 20%;
              font: normal normal normal 1rem/1.375rem opensansSemiBold;
              letter-spacing: 0rem;
              color: #03234a;
              margin-right: 0.5rem;
            }

            .datePicker {
              display: inline-block;
              width: 50%;
              max-width: 16.019rem;
              min-width: 9rem;
              height: 3rem;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 0.063rem solid #cad3dd;
              border-radius: 0.375rem;
            }
          }
        }

        .accordionContainer {
          border: 0.063rem solid #dbe2e6;
          border-radius: 0.375rem;
          height: 66%;
          background-color: #f6f9fa;

          .accordion {
            height: 100%;
            padding: 0 1.875rem 1.25rem 1.875rem;
            overflow-y: auto;

            .customAccordionContainer {
              .customAccordion {
                --bs-accordion-border-width: 0rem;
                --bs-accordion-bg: #f6f9fa;
                --bs-accordion-active-bg: #f6f9fa;
                min-height: 4.688rem;

                .customCard {
                  border: none;

                  .accordionItemHeader {
                    height: 4.688rem;
                    display: flex;

                    .checkboxContainer {
                      display: inline-block;
                      position: relative;
                      padding-left: 1.562rem;
                      cursor: pointer;

                      .checkmark {
                        position: absolute;
                        top: 0.063rem;
                        left: 0;
                        height: 1.25rem;
                        width: 1.25rem;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border: 0.0625rem solid #03234a;
                        border-radius: 0.25rem;
                        opacity: 1;
                      }

                      .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                      }

                      .checkmarkLable {
                        margin-left: 0.5rem;
                        text-align: left;
                        font-size: 1rem;
                        line-height: 1.375rem;
                        font-family: "OpenSansSemiBold";
                        letter-spacing: 0rem;
                        color: #03234a;
                        opacity: 1;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        word-break: break-word;
                      }

                      input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                      }
                    }

                    .checkboxContainer input:checked ~ .checkmark:after {
                      display: block;
                    }

                    .checkboxContainer .checkmark:after {
                      left: 0.375rem;
                      top: 0.125rem;
                      width: 0.3125rem;
                      height: 0.625rem;
                      border: 0.062rem solid #03234a;
                      border-width: 0 0.125rem 0.125rem 0;
                      transform: rotate(45deg);
                    }

                    .accordionItemSubType {
                      display: flex;
                      flex-direction: column;
                    }

                    .accordionHeaderContainer {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-right: 2rem;

                      .checkboxContainer {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.562rem;
                        cursor: pointer;

                        .checkmark {
                          position: absolute;
                          top: 0.063rem;
                          left: 0;
                          height: 1.25rem;
                          width: 1.25rem;
                          background: #ffffff 0% 0% no-repeat padding-box;
                          border: 0.0625rem solid #03234a;
                          border-radius: 0.25rem;
                          opacity: 1;
                        }

                        .checkmark:after {
                          content: "";
                          position: absolute;
                          display: none;
                        }

                        .checkmarkLable {
                          margin-left: 0.5rem;
                          text-align: left;
                          font-size: 1rem;
                          line-height: 1.375rem;
                          font-family: "OpenSansSemiBold";
                          letter-spacing: 0rem;
                          color: #03234a;
                          opacity: 1;
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        }

                        input {
                          position: absolute;
                          opacity: 0;
                          cursor: pointer;
                        }
                      }

                      .checkboxContainer input:checked ~ .checkmark:after {
                        display: block;
                      }

                      .checkboxContainer .checkmark:after {
                        left: 0.375rem;
                        top: 0.125rem;
                        width: 0.3125rem;
                        height: 0.625rem;
                        border: 0.062rem solid #03234a;
                        border-width: 0 0.125rem 0.125rem 0;
                        transform: rotate(45deg);
                      }

                      .customSwitch input {
                        height: 1.25rem;
                        width: 2rem;
                      }

                      .customSwitch input[type="checkbox"]:checked {
                        background-color: #03234a;
                        border-color: #03234a;
                      }
                    }

                    .sidebarfilledSubTitle {
                      margin-left: 2.313rem;
                      font: normal normal normal 0.875rem/1.188rem
                        "OpenSansRegular";
                      color: #333333;
                      position: relative;
                      display: flex;
                      align-items: center;
                    }
                  }

                  .accordionBody {
                    background-color: #fbfdfd;
                    padding: 1.125rem 2.188rem;
                    border-top: 0.063rem solid #cad3dd;

                    .accordionBodyElementsContainer {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-top: 1rem;

                      .checkboxContainer {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.562rem;
                        cursor: pointer;

                        .checkmark {
                          position: absolute;
                          top: 0.188rem;
                          left: 0;
                          height: 1.25rem;
                          width: 1.25rem;
                          background: #ffffff 0% 0% no-repeat padding-box;
                          border: 0.0625rem solid #03234a;
                          border-radius: 0.25rem;
                          opacity: 1;
                        }

                        .checkmark:after {
                          content: "";
                          position: absolute;
                          display: none;
                        }

                        .checkmarkLable {
                          margin-left: 0.5rem;
                          text-align: left;
                          font-size: 1rem;
                          line-height: 1.375rem;
                          font-family: "OpenSansSemiBold";
                          letter-spacing: 0rem;
                          color: #03234a;
                          opacity: 1;
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        }

                        input {
                          position: absolute;
                          opacity: 0;
                          cursor: pointer;
                        }
                      }

                      .checkboxContainer input:checked ~ .checkmark:after {
                        display: block;
                      }

                      .checkboxContainer .checkmark:after {
                        left: 0.375rem;
                        top: 0.125rem;
                        width: 0.3125rem;
                        height: 0.625rem;
                        border: 0.062rem solid #03234a;
                        border-width: 0 0.125rem 0.125rem 0;
                        transform: rotate(45deg);
                      }

                      .customSwitch input {
                        height: 1.25rem;
                        width: 2rem;
                      }

                      .customSwitch input[type="checkbox"]:checked {
                        background-color: #03234a;
                        border-color: #03234a;
                      }

                      .accordionBodyElements {
                        text-align: left;
                        font: normal normal 600 1rem/1.375rem opensansSemiBold;
                        letter-spacing: 0rem;
                        color: #03234a;
                      }
                    }
                  }
                }

                .customCard button {
                  text-align: left;
                  font: normal normal 600 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: #03234a;
                  padding: 1rem 0 1rem 0;
                }

                .customCard button:focus {
                  box-shadow: none;
                }
              }
            }

            .accordionLine {
              width: 100%;
              height: 0rem;
              border: 0.063rem solid #cad3dd;
            }

            .accordionBodyList {
              padding: 0.625rem;
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 1.2rem;
    height: 1.2rem;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d3e2e7;
  }
}
