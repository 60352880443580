.dataQualityContainer {
  width: 100%;
  height: 78.125rem;
  display: flex;
  flex-direction: column;
  background-color: white;

  .dataQualityContainerHeader {
    background-color: #e8eff3;
    display: flex;
    width: 100%;
    height: 6.5%;
    padding: 0.3rem;

    .jobDetailsContainerHeader {
      width: 15%;
      border-radius: 0.375rem 0rem 0rem 0rem;
      background: #cad3dd 0% 0% no-repeat padding-box;
      font: normal normal 600 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .jobTaskContainerHeader {
      width: 15%;
      background: #cad3dd 0% 0% no-repeat padding-box;
      font: normal normal normal 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .selectedJobContainer {
      width: 15%;
      position: relative;
      color: #03234a;
      background: #ffffff 0% 0% no-repeat padding-box;
    }

    .selectedJobContainer::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background: #d0fb56 0% 0% no-repeat padding-box;
    }

    .backButton {
      width: 70%;
      font: normal normal normal 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #293d57;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;

      .dashboardLink {
        cursor: pointer;
      }
    }
  }

  .dataQualityContentContainer {
    width: 100%;
    height: 93.5%;
    display: flex;
    flex-direction: column;

    .chartAndFilterBox {
      width: 100%;
      height: 40%;
      display: flex;
      margin-bottom: 1rem;
      gap: 1rem;
      padding: 1rem;

      .chartBox {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0.063rem solid #5c7b8d;
        border-radius: 0.375rem;
        background-color: #f6f9fa;

        .chartBoxHeader {
          height: 7.7%;
          width: 100%;
          display: flex;

          .orchestrationHeaderHeading {
            width: 90%;
            background-color: #5c7b8d;
            font: normal normal bold 1rem/1.375rem opensansBold;
            letter-spacing: 0rem;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .refreshIcon {
            width: 10%;
            background-color: #5c7b8d;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .chartBoxContent {
          display: flex;
          width: 100%;
          height: 92.3%;
          // justify-content: center;
          align-items: center;
          justify-content: space-around;

          .chartBoxFirstRow {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
            gap: 10px;

            .dataQualityChartBox {
              height: 50%;
              width: 33.33%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              background: #e8eff3;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
              margin: 0.5rem;
            }
          }

          .chartBoxSecondRow {
            width: 20%;
            height: 100%;
            display: flex;
            align-items: center;

            .dataQualityChartBox {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .dataQualityButton {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0.5rem;
                width: 80%;

                .customButton {
                  background-color: #293d57;
                  border: none;
                  font: normal normal bold 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: white;
                  width: 100%;
                }
              }

              .dataQualityStatus {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 80%;
                overflow: auto;

                .dataQualityStatusItem {
                  width: 100%;
                  font: normal normal bold 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: #293d57;
                  margin-top: 0.8rem;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-wrap: wrap;

                  .dataQualityStatusColorRed {
                    background-color: #f80000;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 60%;
                    margin-right: 1rem;
                  }

                  .dataQualityStatusColorGreen {
                    background-color: #119f33;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 50%;
                    margin-right: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      .filterBox {
        width: 30%;
        height: 100%;
        border: 0.063rem solid #5c7b8d;
        border-radius: 0.375rem;
        background-color: #f6f9fa;
        margin-left: 1rem;

        .filterBoxHeader {
          height: 7.7%;
          width: 100%;
          background-color: #5c7b8d;
          font: normal normal bold 1rem/1.375rem opensansBold;
          letter-spacing: 0rem;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .accordionContainer {
          border: 0.063rem solid #dbe2e6;
          border-radius: 0.375rem;
          height: 92.3%;
          background-color: #f6f9fa;

          .clearFilter {
            height: 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 2rem;
            text-decoration: underline;
            font: normal normal bold 1rem/1.375rem opensansSemiBold;
            letter-spacing: 0rem;
            color: blue;
            cursor: pointer;
          }

          .accordion {
            height: 90%;
            padding: 0 1.875rem 1.25rem 1.875rem;
            overflow-y: auto;

            .customAccordionContainer {
              .customAccordion {
                --bs-accordion-border-width: 0rem;
                --bs-accordion-bg: #f6f9fa;
                --bs-accordion-active-bg: #f6f9fa;
                min-height: 4.688rem;

                .customCard {
                  border: none;

                  .accordionItemHeader {
                    height: 4.688rem;
                    display: flex;

                    .checkboxContainer {
                      display: inline-block;
                      position: relative;
                      padding-left: 1.562rem;
                      cursor: pointer;

                      .checkmark {
                        position: absolute;
                        top: 0.063rem;
                        left: 0;
                        height: 1.25rem;
                        width: 1.25rem;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border: 0.0625rem solid #03234a;
                        border-radius: 0.25rem;
                        opacity: 1;
                      }

                      .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                      }

                      .checkmarkLable {
                        margin-left: 0.5rem;
                        text-align: left;
                        font-size: 1rem;
                        line-height: 1.375rem;
                        font-family: "OpenSansSemiBold";
                        letter-spacing: 0rem;
                        color: #03234a;
                        opacity: 1;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        word-break: break-word;
                      }

                      input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                      }
                    }

                    .checkboxContainer input:checked~.checkmark:after {
                      display: block;
                    }

                    .checkboxContainer .checkmark:after {
                      left: 0.375rem;
                      top: 0.125rem;
                      width: 0.3125rem;
                      height: 0.625rem;
                      border: 0.062rem solid #03234a;
                      border-width: 0 0.125rem 0.125rem 0;
                      transform: rotate(45deg);
                    }

                    .accordionItemSubType {
                      display: flex;
                      flex-direction: column;
                    }

                    .accordionHeaderContainer {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-right: 2rem;

                      .checkboxContainer {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.562rem;
                        cursor: pointer;

                        .checkmark {
                          position: absolute;
                          top: 0.063rem;
                          left: 0;
                          height: 1.25rem;
                          width: 1.25rem;
                          background: #ffffff 0% 0% no-repeat padding-box;
                          border: 0.0625rem solid #03234a;
                          border-radius: 0.25rem;
                          opacity: 1;
                        }

                        .checkmark:after {
                          content: "";
                          position: absolute;
                          display: none;
                        }

                        .checkmarkLable {
                          margin-left: 0.5rem;
                          text-align: left;
                          font-size: 1rem;
                          line-height: 1.375rem;
                          font-family: "OpenSansSemiBold";
                          letter-spacing: 0rem;
                          color: #03234a;
                          opacity: 1;
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        }

                        input {
                          position: absolute;
                          opacity: 0;
                          cursor: pointer;
                        }
                      }

                      .checkboxContainer input:checked~.checkmark:after {
                        display: block;
                      }

                      .checkboxContainer .checkmark:after {
                        left: 0.375rem;
                        top: 0.125rem;
                        width: 0.3125rem;
                        height: 0.625rem;
                        border: 0.062rem solid #03234a;
                        border-width: 0 0.125rem 0.125rem 0;
                        transform: rotate(45deg);
                      }

                      .customSwitch input {
                        height: 1.25rem;
                        width: 2rem;
                      }

                      .customSwitch input[type="checkbox"]:checked {
                        background-color: #03234a;
                        border-color: #03234a;
                      }
                    }

                    .sidebarfilledSubTitle {
                      margin-left: 2.313rem;
                      font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
                      color: #333333;
                      position: relative;
                      display: flex;
                      align-items: center;
                    }
                  }

                  .accordionBody {
                    background-color: #fbfdfd;
                    padding: 1.125rem 2.188rem;
                    border-top: 0.063rem solid #cad3dd;

                    .accordionBodyElementsContainer {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding-top: 1rem;

                      .checkboxContainer {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.562rem;
                        cursor: pointer;

                        .checkmark {
                          position: absolute;
                          top: 0.188rem;
                          left: 0;
                          height: 1.25rem;
                          width: 1.25rem;
                          background: #ffffff 0% 0% no-repeat padding-box;
                          border: 0.0625rem solid #03234a;
                          border-radius: 0.25rem;
                          opacity: 1;
                        }

                        .checkmark:after {
                          content: "";
                          position: absolute;
                          display: none;
                        }

                        .checkmarkLable {
                          margin-left: 0.5rem;
                          text-align: left;
                          font-size: 1rem;
                          line-height: 1.375rem;
                          font-family: "OpenSansSemiBold";
                          letter-spacing: 0rem;
                          color: #03234a;
                          opacity: 1;
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        }

                        input {
                          position: absolute;
                          opacity: 0;
                          cursor: pointer;
                        }
                      }

                      .checkboxContainer input:checked~.checkmark:after {
                        display: block;
                      }

                      .checkboxContainer .checkmark:after {
                        left: 0.375rem;
                        top: 0.125rem;
                        width: 0.3125rem;
                        height: 0.625rem;
                        border: 0.062rem solid #03234a;
                        border-width: 0 0.125rem 0.125rem 0;
                        transform: rotate(45deg);
                      }

                      .customSwitch input {
                        height: 1.25rem;
                        width: 2rem;
                      }

                      .customSwitch input[type="checkbox"]:checked {
                        background-color: #03234a;
                        border-color: #03234a;
                      }

                      .accordionBodyElements {
                        text-align: left;
                        font: normal normal 600 1rem/1.375rem opensansSemiBold;
                        letter-spacing: 0rem;
                        color: #03234a;
                      }
                    }
                  }
                }

                .customCard button {
                  text-align: left;
                  font: normal normal 600 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: #03234a;
                  padding: 1rem 0 1rem 0;
                }

                .customCard button:focus {
                  box-shadow: none;
                }
              }
            }

            .accordionLine {
              width: 100%;
              height: 0rem;
              border: 0.063rem solid #cad3dd;
            }

            .accordionBodyList {
              padding: 0.625rem;
            }
          }
        }
      }

      // .graphBox {
      //   width: 35%;
      //   height: 100%;
      //   border: 0.063rem solid #5c7b8d;
      //   border-radius: 0.375rem;
      //   background-color: #f6f9fa;
      //   margin-left: 1rem;
      //   display: flex;
      //   flex-direction: column;

      //   .graphBoxHeader {
      //     height: 7.7%;
      //     width: 100%;
      //     background-color: #5c7b8d;
      //     font: normal normal bold 1rem/1.375rem opensansBold;
      //     letter-spacing: 0rem;
      //     color: white;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //   }

      //   .graphContainer {
      //     height: 92.3%;
      //     width: 100%;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //   }
      // }
    }

    .tableBox {
      width: 100%;
      height: 40%;
      border: 1px solid #5c7b8d;
      border-radius: 6px;
      background-color: #f6f9fa;

      .tableBoxHeader {
        height: 14.3%;
        width: 100%;
        background-color: #5c7b8d;
        font-weight: bold;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      .jobManagementMainContainer {
        height: 85%;
        background: #ffffff;
        border-radius: 6px;
        overflow-x: auto;
        width: 100%;
      }

      .jobManagementTableStrip {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: 10px 15px;
          /* Padding inside cells */
          border: 1px solid #8f8f8f;
          /* Slightly darker border between cells */
          min-width: 150px;
          /* Minimum width for each cell */
          text-align: left;
        }

        thead th {
          background-color: rgba(204, 212, 221, 0.9);
          /* Header background */
          color: #03234a;
          /* Header text color */
          font-weight: 600;
          // border-bottom: 2px solid #5c7b8d;
          /* Darker bottom border for headers */
        }

        tbody tr:nth-child(odd) {
          background-color: #ffffff;
          /* Background color for odd rows */
        }

        tbody tr:nth-child(even) {
          background-color: rgba(232, 239, 243, 0.4);
          /* Background color for even rows */
        }

        tbody td {
          color: #000000;
          /* Text color for table cells */
          font-weight: normal;
        }
      }

      .wideColumn {
        min-width: 13rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }

  .graphBox {
    width: 100%;
    /* Takes the full width in the next row */
    height: 40%;
    /* Adjust height as needed */
    border: 0.063rem solid #5c7b8d;
    border-radius: 0.375rem;
    background-color: #f6f9fa;
    margin-top: 1rem;
    /* Ensure some space between the sections */
    display: flex;
    flex-direction: column;

    .graphBoxHeader {
      height: 7.7%;
      width: 100%;
      background-color: #5c7b8d;
      font: normal normal bold 1rem/1.375rem opensansBold;
      letter-spacing: 0rem;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .graphContainer {
      height: 92.3%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::-webkit-scrollbar {
    width: 0rem;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d3e2e7;
  }
}


.chartBox,
.filterBox,
.graphBox {
  border: 1px solid #cad3dd;
  border-radius: 8px;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}