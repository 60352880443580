.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.tooltipContainer {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  line-height: 1.4;
  white-space: nowrap;
  font: normal normal 600 0.875rem/1.188rem "OpenSansRegular";
}
