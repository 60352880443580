.selected-data-set-container {
    min-width: 400px;
    min-height: 280px;
    max-height: 290px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.data-source-list {
    margin-left: 45px;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
    overflow-y: auto;
    max-height: 100%;
}

.data-source-item {
    margin-bottom: 10px;
    margin-top: 15px;
}

.data-source-header {
    display: flex;
    align-items: left;
    justify-content: left;
    white-space: nowrap;
    cursor: pointer;
}

.endpoint-list {
    list-style-type: none;
    padding-left: 60px;
    white-space: nowrap;
    margin-top: 10px;
}

.endpoint-item {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
}

.footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
}

.checkbox {
    margin-right: 10px;
}

.fixedHeader {
    background: #03234a;
    color: white;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.HeadingText {
    text-align: center;
    padding-left: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.btn-save {
    background: #293d57 !important;
    border: none !important;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }
}