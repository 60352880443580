.help-dropdown-menu {
    /* Adjust horizontal shift: negative value moves left */
    transform: translateX(-70px);
    /* Alternatively, you might try margin adjustments:
       margin-left: -30px;
    */
}

.helpItem {
    color: #03234a !important;
    /* default inherits color */
    font-weight: 600 !important;
    transition: color 0.2s, font-weight 0.2s;
}

.helpItem:active,
.helpItem:focus {
    color: #03234a !important;
    font-weight: 600 !important;
    background: white !important;
}