.side-navbar-routes-container {
  height: 100%;
  width: 100%;
  background-color: #03234a;
  color: #fff;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular";
  padding-top: 0.5rem;
}

.nav-sidebar-outer-container {
  height: 100%;
  width: 100%;

  .custom-fluid {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .custom-navbar {
    width: 100%;
    padding: 0.5rem 0 1rem 0;
    display: flex;
    flex-direction: column;

    .nav-item-inner-container {
      color: #fff !important;
      --bs-nav-link-color: #fff !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1.75rem;
      font: normal 600 1rem/1.375rem "OpenSansSemibold";
      height: 100%;

      #navBar_dashboard_users {
        width: 100%;
        background-color: #03234a;

        .nav-link {
          width: 100%;
          height: 2.75rem;
          display: flex;
          padding: 0.5rem;
          justify-content: flex-start;
          align-items: center;
          border-radius: 0.5rem;
          transition: all 0.2s ease-in;
        }

        &:hover {
          background-color: #f0f0f0;
          color: #000 !important;
          --bs-nav-link-hover-color: #000 !important;
          outline: 0;
          display: flex;
          align-items: center;
          border-radius: 0.5rem;
        }

        .nav-link.active {
          width: 100%;
          position: relative;
          outline: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: #f0f0f0;
          border-radius: 0.5rem;


          .route-container {
            width: 100%;

            #group2357 {
              svg * {
                fill: #000 !important;
                stroke: #000 !important;
              }
            }

            #group2417 {
              svg * {
                fill: #000 !important;
                stroke: #000 !important;
              }
            }

            #dbSource {
              svg * {
                fill: #000 !important;
                stroke: #000 !important;
              }

            }

          }
        }
      }

      .route-container {
        width: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;


        .icon-container {
          width: 46px;
        }

        #group2357 {
          svg {
            height: 46px;
            width: 46px;
            transform: translate(-12px, -8px);
          }

          svg * {
            fill: #ffffff !important;
            stroke: #ffffff !important;
          }

          svg circle {
            display: none;
          }
        }

        #adminIcon:hover {
          // border-radius: 0.5rem;
          // color: #000;
          background-color: #f0f0f0;
        }

        // #adminIcon:active {
        //   // border-radius: 0.5rem;
        //   // color: #000;
        //   background-color: #f0f0f0;
        // }

        #group2417 {
          svg {
            height: 42px;
            max-width: 42px;
            transform: translate(-12px, -6px);
          }

          #Group_2417>path:first-of-type {
            display: none;
          }

          svg * {
            fill: #ffffff !important;
            stroke: #ffffff !important;
          }
        }

        #dbSource {

          svg * {
            fill: #ffffff !important;
            stroke: #ffffff !important;
          }

          svg circle {
            display: none;
          }
        }

        &:hover {
          #group2357 {
            svg * {
              fill: #000 !important;
              stroke: #000 !important;
            }
          }

          #dbSource {
            svg * {
              fill: #000 !important;
              stroke: #000 !important;
            }
          }

          #group2417 {
            svg * {
              fill: #000 !important;
              stroke: #000 !important;
            }
          }
        }

        .route-icon {
          height: 32px;
          width: 32px;
        }
      }

      .sign-out-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        transition: all 0.2s ease-in;
        padding: 0;
      }

      .sign-out-container-v2 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 80%;
        transition: all 0.2s ease-in;
        padding: 0;
      }

      #sign-out {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
        opacity: 1;
        border-radius: 0.5rem;
        padding: 10px 0;
        /* Add padding for better spacing */
      }

      #sign-out:hover {
        border-radius: 0.5rem;
        color: #000;
        background-color: #f0f0f0;
      }
    }
  }
}