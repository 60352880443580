@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

.wade-portal {
  height: 100%;
  min-height: 100%;
}

.catalogueContainer {
  background: #e8eff3;
  height: 91%;
  padding: 30px 0px 30px 0px;
}

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "openSansRegular";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "openSansBold";
  src: url("./fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "openSansSemiBold";
  src: url("./fonts/OpenSans-SemiBold.ttf");
}

.modal-backdrop {
  opacity: 0.9 !important;
  background-color: #03234a !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}