.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .reactFlow {
    width: 78%;
    height: 100%;
    border: 0.063rem solid #cad3dd;
  }

  .dependencyDetails {
    width: 22%;
    height: 100%;
    border: 0.063rem solid #cad3dd;
    border-radius: 0.375rem;
    margin-left: 0.5rem;

    .dependencyDetailsHeader {
      text-align: left;
      font: normal normal 600 1.125rem/1.5rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #03234a;
      margin-bottom: 0.3rem;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
    }

    .dependencyDetailsName {
      text-align: center;
      font: normal normal 600 1.5rem/2.063rem opensansBold;
      letter-spacing: 0rem;
      color: #03234a;
      margin-bottom: 0.3rem;
      margin-top: 1rem;
    }

    .dependencyDetailsContent {
      height: 100%;
      .jobJobContainer {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .noJobText {
          width: 100%;
          padding: 0 1rem;
          text-align: center;
          font: normal normal 600 1.125rem/1.5rem opensansSemiBold;
          letter-spacing: 0rem;
          color: #03234a;
        }
      }

      .selectedJobDetails {
        .jobName {
          width: 11.125rem;
          height: 2.063rem;
          text-align: left;
          font: normal normal bold 1.5rem/2.063rem opensansSemiBold;
          letter-spacing: 0rem;
          color: #03234a;
        }

        .linkedJobDetails {
          margin-top: 1rem;
          width: 100%;
          background: #ffffff 0% 0% no-repeat padding-box;
          // border: 0.063rem solid #abb8c3;
          // border-radius: 0.5rem;
          padding: 0.5rem 1rem;
        }

        .linkedJobHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .linkedJobHeaderName {
            height: 2.063rem;
            text-align: left;
            font: normal normal 600 1.5rem/2.063rem opensansSemiBold;
            letter-spacing: 0rem;
            color: #03234a;
          }

          .deleteFile {
            margin-left: 1.25rem;
            cursor: pointer;
          }

          .editIcon {
            cursor: pointer;
          }
        }
      }
    }
  }
}
