.emailMain {
  --bs-modal-width: 56.44rem !important;
  --bs-modal-height: 55.125rem !important;
  padding: 0 !important;
  .emailMainBody {
    background-color: #ffffff;
    padding: 13.9rem 7.1rem;
    border-radius: 0.375rem;
    opacity: 1;
    .emailBodyHead {
      margin: 4.164rem 0rem 4.5rem 0rem;
      p {
        color: #03234a;
        font: normal normal 600 2rem/2.688rem "OpenSansSemiBold";
        letter-spacing: 0rem;
        margin: 0;
        opacity: 1;
      }
    }
    #emailLabel {
      font: normal normal normal 1rem/1.375rem "OpenSansRegular";
      color: #03234a;
      margin: 0rem 0rem 0.75rem 0rem;
    }
    #emailInput {
      width: 25rem;
      height: 3rem;
    }
    #emailWrongInput {
      width: 25rem;
      height: 3rem;
      background-color: #ffffff !important;
      border-radius: 0.375rem;
      color: #e88181;
      border: 0.063rem solid #e88181;
    }
    .emailBodyHeadWade {
      h6 {
        color: #03234a;
        font: normal normal 600 0.938rem/1.375rem Open Sans;
        letter-spacing: 0rem;
        color: #03234a;
        opacity: 1;
      }
    }
    .emailBodyOptionEmail {
      .emailBodyEmail {
        input {
          width: 15.625rem;
        }
        .invalidMessageDisplay {
          width: 25rem;
          font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
          color: #e88181;
          padding: 0.625rem;
          padding-left: 0rem;
        }
      }
    }
    .emailBodyBTNBody {
      .emailBodyBTN {
        color: #d0fb56;
        background-color: #03234a;
        width: 25rem;
        font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
        height: 3rem;
        margin-top: 3rem;
      }
    }
  }
}
