.loginPageContainer {
  width: 100%;
  background-color: #e8eff3;
  display: flex;

  @media screen and (min-width: 120rem) {
    height: 100%;
  }

  .loginPageLeftPanel {
    margin-top: 0rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../Icons/LoginPageLeftPanelBackground.png");
    background-size: cover;
    background-repeat: round;
    background-color: transparent;
    padding-top: 7.5%;
    padding-right: 7%;
  }

  #loginLeftIconId {
    margin-bottom: 5.375rem;
  }

  .loginPageMainHeading {
    font: normal normal bold 2.5rem/3.438rem "OpenSansBold";
    margin-bottom: 2.437rem;
    color: #fff;
    width: 38rem;
    height: 6.875rem;
  }

  .loginPageSubHeading {
    font: normal normal normal 1.25rem/1.688rem "OpenSansRegular";
    width: 38rem;
    height: 5.0625rem;
    color: #fff;
    text-align: left;
  }

  .loginPageRightPanel {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 10%;
  }

  #wadeLogoIconId {
    width: 15.25rem;
    height: 2.625rem;
  }

  #loginPageLoginHeading {
    margin-top: 3.9375rem;
    margin-bottom: 2.5625rem;
    font: normal normal 600 2rem/2.688rem "OpenSansSemiBold";
    color: #03234a;
  }

  #loginPagePasswordInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
  }

  #loginPageEmailInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;

    &:focus {
      outline: none !important;
      border: 0.063rem solid #ced4da !important;
    }
  }

  #loginPageLabelInput {
    color: #03234a;
    white-space: nowrap;
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    margin-top: 1.5rem;
  }

  #loginPagePasswordInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;

    &:focus {
      outline: none !important;
      border: 0.063rem solid #ced4da !important;
    }
  }

  #loginPagePasswordWrongInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
    color: #e88181;
    border: 0.063rem solid #e88181;

    &:focus {
      outline: none !important;
      border: 0.063rem solid #e88181 !important;
    }
  }

  #loginPageEmailPasswordEmpty {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
    border: 0.063rem solid #e88181;
  }

  .loginPageForgetPassword {
    margin-top: 1.25rem;
    color: #03234a;
    font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
    color: #03234a;
    opacity: 1;
    cursor: pointer;
  }

  #loginPageNewPasswordProceedButton {
    width: 25rem !important;
    height: 3rem !important;
    line-height: 1.25rem;
    margin-top: 0.875rem;
    margin-bottom: 15rem;
    background-color: #03234a;
    font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
    color: #d0fb56;
    margin-left: 0rem;
  }

  #loginCopyRightText {
    position: absolute;
    bottom: 0;
    margin: 0rem 0rem 5% 0rem;
    font: normal normal normal 0.75rem/1.063rem "OpenSansRegular";
    color: #03234a;

    #innerCopyRightText {
      margin: 0rem 1.25rem 0rem 1.25rem;
    }
  }

  #loginCopyRightText {
    a {
      text-decoration: none;
      color: #03234a;
    }
  }

  .loginInvalidmailId {
    width: 25rem;
    font: normal normal normal 0.875rem/1.188rem "OpenSansRegular";
    color: #e88181;
  }

  .emailImgContainer {
    height: 3rem;
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: 9;
    padding: 1rem;
    border-left: 0;

    &:focus {
      outline: none !important;
      border: 0.063rem solid #ced4da !important;
    }
  }

  .emailError {
    border: 0.063rem solid #e88181 !important;
    border-left: none !important;
    height: 3rem;
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: 9;
    border-left: 0;
    padding: 1rem;
  }
}

#loginDisabled {
  width: 25rem !important;
  height: 3rem !important;
  line-height: 1.25rem;
  margin-top: 0.875rem;
  margin-bottom: 15rem;
  font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
  color: #aaa;
  background-color: #e7e7e7;
  border: 1px solid #ddd;
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}