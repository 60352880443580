.doughnutContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .doughnutChart {
    height: 65%;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .doughnutChartHome {
    height: 80%;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .icon {
    position: absolute;
  }

  .textStyleJobHome {
    width: 100%;
    height: 15%;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 1rem/1.375rem opensansSemiBold;
    letter-spacing: 0rem;
    color: #293d57;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .textStyleJob {
    width: 100%;
    height: 15%;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 0.9rem/1.375rem opensansSemiBold;
    letter-spacing: 0rem;
    color: #293d57;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
