.form-label {
  justify-content: space-between;
  font-size: 0.875rem;
}

#environmentInputs {
  margin-right: 4.688rem;
  width: 78.5% !important;
  height: 3rem;
}

.input-required {
  color: red;
}

#userAdminInput {
  margin-right: 4.688rem;
  width: 80% !important;
  height: 3rem;
}

#custumPlugInInput {
  margin-right: 4.688rem;
  width: 85% !important;
  height: 3rem;
}

#custumPlugInDescription {
  margin-right: 4.688rem;
  width: 92.5% !important;
  height: 3rem;
}

#ensembleModellingDescription {
  margin-right: 4.688rem;
  width: 92.5% !important;
  height: 3rem;
}

#dataSourceDetailsInput {
  width: 90%;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#importDataSourceDetailsInput {
  width: 20.313rem;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#importDataSourcesdetails {
  width: 20.625rem;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#dataSourceDetailsInputSettings {
  width: 90%;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#importDataSourcesdetailsSettings {
  width: 19.375rem;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#dataSourceDetailsInputFL {
  width: 100%;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#importDataSourcesdetailsFL {
  width: 40.313rem;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#dataSourceDetailsInputTop {
  width: 90%;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}
#ImportDataSourceDetailsInputTop {
  width: 21.25rem;
  height: 3rem;
  margin: 0rem !important;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #000000;
  opacity: 1;
}

.form-control {
  border-top: 0rem;
  border-left: 0rem;
  border-right: 0rem;
  margin-bottom: 0.313rem;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

#loginPageEmailInput {
  width: 16.25rem;
}

#loginPageEmailLabelInput {
  color: #03234a;
  white-space: nowrap;
}

#loginPagePasswordInput {
  width: 16.25rem;
}

#loginPagePasswordLabelInput {
  color: #03234a;
}

#dataSourceDetailsInputLabel {
  margin: 1.5rem 0rem 0.75rem !important;
  text-align: left;
  font-size: 1rem !important;
  line-height: 1.375rem !important;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #03234a !important;
  opacity: 1;
}

#dataSourceSearchInput {
  width: 14.063rem;
  height: 2rem;
  border: 0.063rem solid #cad3dd;
  border-radius: 0.25rem;
  opacity: 1;
}

#importDataModelSearchInput {
  width: 12vw;
}

#subjectAreaNameInput {
  height: 3rem;
  width: 41.5%;
}

#DataSourceEndPointLable {
  text-align: left;
  font-size: 1rem !important;
  line-height: 1.375rem !important;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #03234a;
  opacity: 1;
}

#environmentInputsLable {
  margin: 1.5rem 0rem 0.75rem 0rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: "openSansRegular" !important;
  letter-spacing: 0rem;
  color: #03234a;
  opacity: 1;
}

.toolTipMessage .tooltip-inner {
  background-color: #ffffff;
  text-align: left;
  font-family: 1.125rem;
  line-height: 1.25rem;
  font-weight: "openSansregular";
  letter-spacing: 0rem;
  color: #666666;
  border: 0.125rem solid #d0fb56;
  opacity: 1;
  padding: 0.625rem;
}

.InputInfoIcon {
  margin-left: 0.313rem;
}
.inputInfoIconCustomPlugIn {
  margin-left: 0.375rem;
  margin-top: 0.125rem;
}
.toolTipMessage {
  opacity: 1 !important;
}
