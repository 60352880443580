.nodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    position: absolute;
    right: 0.625rem;
    bottom: 0.5rem;
    cursor: pointer;
  }

  .settingIcon {
    position: absolute;
    left: 0.625rem;
    top: 0.5rem;
  }

  .link {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    letter-spacing: 0rem;
    color: #03234a;
    cursor: pointer;
  }

  .label {
    text-align: left;
    font: normal normal 600 1rem/2rem "OpenSansSemiBold";
    letter-spacing: 0rem;
    color: #03234a;
  }

  .minusIcon {
    position: relative;
    bottom: 0.688rem;
    right: 0.5rem;
  }

  .plusIcon {
    position: relative;
    bottom: 0.688rem;
    right: 0.688rem;
    z-index: 1;
  }
}

.nodeStyle {
  background-color: rgb(166 220 232);
  border: 0.063rem solid #abb8c3;
  border-radius: 0.5rem;
  padding: 0.875rem 2rem;
  height: 8rem;
  width: 9rem;
}

.nodeStyleSelected {
  border: 0.225rem solid blue !important;
  background-color: rgb(166 220 232);
  border-radius: 0.5rem;
  padding: 0.875rem 2rem;
  height: 8rem;
  width: 9rem;
}
