.nodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    position: absolute;
    right: 0.625rem;
    bottom: 0.5rem;
    cursor: pointer;
  }

  .settingIcon {
    position: absolute;
    left: 0.625rem;
    top: 0.5rem;
  }

  .link {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    letter-spacing: 0rem;
    color: #03234a;
    cursor: pointer;
  }

  .label {
    text-align: left;
    font: normal normal 600 1.5rem/2rem "OpenSansSemiBold";
    letter-spacing: 0rem;
    color: #03234a;
  }

  .minusIcon {
    position: relative;
    bottom: 0.688rem;
    right: 0.5rem;
  }

  .plusIcon {
    position: relative;
    bottom: 0.688rem;
    right: 0.688rem;
    z-index: 1;
  }
}

.nodeStyle {
  padding: 2rem;
  height: 16rem;
  width: 18rem;
  border-style: dashed;
  border-width: 4px;
  border-color: #282e17;
  border-spacing: 50px;
  padding: 1.5rem;
  background-color: white;
}

.nodeStyleSelected {
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.1), 0 0 10px rgba(0, 255, 0, 0.08),
    0 0 20px rgba(0, 255, 0, 0.06), 0 0 30px rgba(0, 255, 0, 0.04);
  border-style: dashed;
  border-width: 4px;
  border-color: #282e17;
  padding: 1.5rem;
  background-color: rgb(254, 254, 254);
  padding: 2rem;
  height: 17rem;
  width: 18rem;
}
