.toolTipMessage {
  opacity: 1 !important;
  width: 22rem !important;
  background-color: #f7f7f7;
  text-align: left;
  font-weight: 1.125rem;
  line-height: 1.25rem;
  font-family: "openSansRegular";
  letter-spacing: 0rem;
  color: #666666;
  border: 0.125rem solid #14122c !important;
  opacity: 1;
  padding: 0.625rem;
  // min-width: fit-content
}

.toolTipMessage .tooltip-inner {
  // background-color: #f7f7f7;
  // text-align: left;
  // font-weight: 1.125rem;
  // line-height: 1.25rem;
  // font-family: "openSansRegular";
  // letter-spacing: 0rem;
  // color: #666666;
  border: none !important;
  // opacity: 1;
  // padding: 0.625rem;
  min-width: fit-content !important;
}

.inputInfoIcon {
  margin-left: 0.313rem;
}