.msAuthenticatorMain {
  --bs-modal-width: 56.46rem !important;
  --bs-modal-height: 55.125rem !important;
  padding: 0 !important;
  .msAuthenticatorMainBody {
    background-color: #ffffff;
    padding: 9.35rem 11.9rem;
    border-radius: 0.37rem;
    .msAuthenticatorBodyHead {
      .qrcode {
        height: 13.75rem !important;
        width: 13.75rem !important;
        padding: 0.87rem;
      }
      .msAuthenticatorBodyQRCode {
        border: 0.063rem solid #cad3dd;
      }
      h6 {
        color: #03234a;
        font: normal normal 600 2rem/2.68rem "OpenSansSemiBold";
        letter-spacing: 0rem;
        opacity: 1;
      }
    }
    .msAuthenticatorBodyHeadTitle {
      h6 {
        color: #03234a;
        font: normal normal 600 2rem/2.68rem "OpenSansSemiBold";
        letter-spacing: 0rem;
        margin: 4.16rem 0rem 3.5rem 0rem;
        width: 25.5rem;
      }
    }
    .msAuthenticatorBodyOptionEmail {
      .msAuthenticatorBodyEmail {
        input {
          width: 15.62rem;
        }
      }
    }

    .msAuthenticatorBodyBTNBody {
      .msAuthenticatorBodyBTN {
        margin-top: 4.25rem;
        color: #d0fb56;
        background-color: #03234a;
        width: 25rem;
        height: 3rem;
        font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
      }
    }
  }
}
