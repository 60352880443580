.navbar-container {
  height: 4rem;
  width: 100%;
  background-color: #03234a;
  color: #fff;
  font-size: 1rem;
  line-height: 1.375rem;
  border-bottom: 1px solid #ececec;
  font-family: "openSansRegular";
}

.nav-header-outer-container {
  height: 100%;
  width: 100%;
  .custom-header-fluid {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem 0 2rem;
    .custom-brand {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .environment_inner_container {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    #basic-nav-dropdown-link_profileIcon {
      display: flex;
      color: #03234a !important;
      text-align: left;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.375rem;
      font-family: "OpenSansBold";
      letter-spacing: 0rem;
      opacity: 1;
      padding: 0.5rem;
      border-radius: 8px;
      &:hover {
        background-color: #e8e8e8;
      }
    }
    .navbar-environment {
      border: 0.063rem solid #cad3dd;
      width: 13rem;
      border-radius: 0.375rem;
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: center;
      .dropdown {
        .dropdown-menu[data-bs-popper] {
          position: absolute;
          left: -3rem;
          top: 1.75rem;
          width: 14rem;
          border-radius: 0.375rem;
          padding: 0.75rem 0.75rem 0.25rem 0.75rem;
        }
      }
      .nav-item.dropdown.show .dropdown-toggle::after {
        transform: rotate(180deg) !important;
      }
      .nav-item .dropdown-toggle::after {
        display: inline-flex !important;
        color: #d0fb56 !important;
      }
      #basic-nav-dropdown {
        font: normal normal normal 1.1rem/1.375rem "OpenSansRegular";
      }
      #basic-nav-dropdown-environment-Button {
        Button {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 0.5rem;
          text-align: center;
          font-size: 1rem;
          line-height: 1.375rem;
          font-family: "OpenSansRegular";
          letter-spacing: 0rem;
          color: #03234a;
          opacity: 1;
          background-color: #e3f7ad;
          border: none;
          text-align: left;
          width: 100%;
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.015);
          }
        }
      }
      .environment-dropdown-items {
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
        padding-top: 1rem;
        #basic-nav-dropdown-link-env {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #03234a !important;
          text-align: left;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.375rem;
          font-family: "OpenSansBold";
          letter-spacing: 0rem;
          opacity: 1;
          padding: 0.15rem 0.15rem 0.15rem 0.25rem;
          border-radius: 8px;

          img {
            height: 1.5rem !important;
            width: 1.5rem !important;
            margin: 0.313rem;
            border-radius: 0.938rem;
            background-color: #e8eff3;
            border: 0.063rem solid #adb8c2;
          }
          &:hover {
            background-color: #e8e8e8;
          }
        }
      }
    }

    .dropdown {
      #user-nav-dropdown {
        display: flex;
        align-items: center;
      }
      .dropdown-menu[data-bs-popper] {
        width: 10rem;
        background-color: #ffffff;
        left: 2rem;
        top: 2.5rem;
        padding: 0.625rem;
      }
      .dropdown-toggle::after {
        display: inline-flex !important;
        color: #d0fb56 !important;
      }
    }
    .nav-item.dropdown.show .dropdown-toggle::after {
      transform: rotate(180deg) !important;
    }

    .navbar-profile {
      display: flex;
      align-items: center;
      width: 100%;

      .navbar-email {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        word-wrap: break-word;
        margin-right: 0.25rem;
      }
    }
  }
}
