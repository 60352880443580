.modalDependencyContainer {
  width: 100%;
  height: 49rem;
  display: flex;

  .modalDependencyGraphContainer {
    width: 60%;
    height: 100%;
    border: 0.063rem solid #cad3dd;
    border-radius: 0.375rem;
    margin-right: 0.5rem;
    background-color: white;

    .modalDependencyGraphContainerHeader {
      height: 8%;
      display: flex;
      border-bottom: 0.063rem solid #cad3dd;
      border-radius: 0.375rem;

      .backArrow {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.938rem;
        cursor: pointer;
      }

      .backButton {
        width: 20%;
        margin-left: 1.875rem;
        font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
        letter-spacing: 0rem;
        color: #03234a;
        display: flex;
        align-items: center;
      }

      .addButton {
        width: 30%;
        margin-left: 1.875rem;
        font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
        letter-spacing: 0rem;
        color: #03234a;
        display: flex;
        align-items: center;

        .createJobIcon {
          cursor: pointer;
          margin-left: 2rem;
          margin-right: 1rem;
        }
      }

      .headerName {
        width: 60%;
        margin-right: 1.875rem;
        font: normal normal bold 1.125rem/1.5rem "OpenSansBold";
        letter-spacing: 0rem;
        color: #03234a;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .modalDependencyGraphContainerBody {
      height: 92%;
    }
  }

  .modalDependencyDetailsContainer {
    width: 40%;
    height: 100%;
    border: 0.063rem solid #cad3dd;
    border-radius: 0.375rem;
    background-color: white;
    padding: 1.875rem;
    overflow: auto;

    .header {
      font: normal normal bold 1.5rem/2.063rem "OpenSansBold";
      color: #03234a;
      margin-left: 0rem;
      display: flex;
      justify-content: space-between;

      .deleteIcon {
        cursor: pointer;
      }
    }

    .contentContainer {
      height: 100%;
      .description {
        width: 100%;
        margin-top: 1rem;

        .formGroup {
          display: flex;

          .label {
            padding: 1rem 0 0.75rem 0;
            text-align: left;
            width: 15rem;
            font: normal normal normal 1rem/1.375rem opensansRegular;
            letter-spacing: 0rem;
            color: #03234a;
            margin-right: 2rem;
          }

          .input {
            width: 50%;
            height: 3rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 0.063rem solid #cad3dd;
            border-radius: 0.375rem;
            text-align: left;
            font: normal normal normal 1rem/1.375rem opensansRegular;
            letter-spacing: 0rem;
            color: #03234a;
          }

          .disabledInput {
            width: 50%;
            height: 3rem;
            background: #e9ecef 0% 0% no-repeat padding-box;
            border: 0.063rem solid #cad3dd;
            border-radius: 0.375rem;
            text-align: left;
            font: normal normal normal 1rem/1.375rem opensansRegular;
            letter-spacing: 0rem;
            color: #03234a;
          }
        }
      }

      .dependencyDetails {
        width: 100%;

        .dependencyDetailsHeader {
          text-align: left;
          font: normal normal bold 1.5rem/2.063rem "OpenSansBold";
          letter-spacing: 0rem;
          color: #03234a;
          margin-bottom: 0.3rem;
          margin-top: 2rem;
        }

        .dependencyDetailsName {
          text-align: center;
          font: normal normal 600 1.5rem/2.063rem opensansBold;
          letter-spacing: 0rem;
          color: #03234a;
          margin-bottom: 0.3rem;
          margin-top: 1rem;
        }

        .dependencyDetailsContent {
          .jobJobContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .noJobText {
              padding-top: 1.625rem;
              width: 10.563rem;
              text-align: center;
              font: normal normal 600 1.125rem/1.5rem opensansSemiBold;
              letter-spacing: 0rem;
              color: #03234a;
            }
          }

          .selectedJobDetails {
            .jobName {
              width: 11.125rem;
              height: 2.063rem;
              text-align: left;
              font: normal normal bold 1.5rem/2.063rem opensansSemiBold;
              letter-spacing: 0rem;
              color: #03234a;
            }

            .linkedJobDetails {
              margin-top: 1rem;
              width: 100%;
              background: #ffffff 0% 0% no-repeat padding-box;
            }

            .linkedJobHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .linkedJobHeaderName {
                height: 2.063rem;
                text-align: left;
                font: normal normal 600 1.5rem/2.063rem opensansSemiBold;
                letter-spacing: 0rem;
                color: #03234a;
              }

              .deleteFile {
                margin-left: 1.25rem;
                cursor: pointer;
              }

              .editIcon {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .noCbcSelected {
      width: 100%;
      height: 100%;
      font: normal normal 600 1.125rem/1.5rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #03234a;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
