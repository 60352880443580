.textStyleJob {
  position: absolute;
  width: 100%;
  height: 12%;
  top: 80%;
  text-align: center;
  font: normal normal bold 1rem/1.375rem opensansSemiBold;
  letter-spacing: 0rem;
  color: #293d57;
}

.textStyleJtg {
  position: absolute;
  width: 100%;
  height: 12%;
  top: 75%;
  text-align: center;
  font: normal normal bold 1rem/1.375rem opensansSemiBold;
  letter-spacing: 0rem;
  color: #293d57;
  margin-top: 0.5rem;
}
