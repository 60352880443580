.GuideEnvironmentCloseIconModal {
    --bs-modal-width: 42.756rem !important;
    --bs-modal-height: 34.946rem !important;

    .modalBodayMain {
        border-radius: 1rem;
        background-color: #E8EFF3;
        opacity: 1;
        
        .GuideEnvironmentCloseIcon {
            display: flex;
            justify-content: end;
            margin: 0.625rem 0.625rem  3.625rem;
        }
        .GuideEnvironmentSUBCloseIcon {
            display: flex;
            justify-content: end;
            margin: 0.625rem 0.625rem 0.366rem;
        }
        .modalBoday {
            margin: 1.875rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 3.125rem;

            .modalBodayDark {
                letter-spacing: 0rem;
                color: #03234A;
                font-weight: bold;
                text-align: center;
                font-size: 1.625rem;
                line-height: 2.25rem;
                font-family: "openSansBold";
                margin: 0.3125rem;
            }

            .modalBodayFooterMessage {
                margin-top: 1.562rem;
            }

            .modalBodayLite {
                letter-spacing: 0rem;
                color: #03234A;
                text-align: center;
                font-size: 1.625rem;
                line-height: 2.25rem;
                font-family: "openSansRegular";
            }
        }

        .ConfirmEnvironmentFooter {
            display: flex;
            justify-content: center;
            margin: 4.081rem 0rem;

            .ConfirmEnvironmentFooterNo {
                width: 10rem;
                height: 3.125rem;
                margin: 0.937rem;
                background: #E8EFF3 0% 0% no-repeat padding-box;
                border: 0.062rem solid #03234A;
                border-radius: 0.375rem;
                font-family: "openSansBold";
                letter-spacing: 0rem;
                color: #03234A;
                font-weight: bold;
                font-size: 1.125rem;
                line-height: 1.5rem;
                text-transform: uppercase;
                opacity: 1;
            }

            .ConfirmEnvironmentFooterYes {
                width: 10rem;
                height: 3.125rem;
                margin: 0.937rem;
                letter-spacing: 0rem;
                padding-top: 0.625rem;
                color: #D0FB56;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.125rem;
                line-height: 1.5rem;
                background: #03234A 0% 0% no-repeat padding-box;
                border-radius: 0.375rem;
                opacity: 1;
            }
        }
    }
}