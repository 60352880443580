._WuQ0f:last-child,
._WuQ0f:nth-child(3) {
  display: none;
}

._3lLk3:last-child,
._3lLk3:nth-child(2) {
  display: none;
}

._2eZzQ {
  display: none;
}

._2pZMF {
  display: none;
}
._2RbVy {
  opacity: 1;
}

.large {
  font-size: 30px;
}

._3_ygE {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f6f9fa;
}

._CZjuD {
  overflow: visible;
}

._2k9Ys {
  overflow: auto;
  max-width: 100%;
  scrollbar-width: thin;
  height: 0rem;
}

._2TfEi {
  padding-left: 2rem;
}
