.dashboardMainContainer {
  width: 100%;
  height: 70rem;
  background: #e8eff3;
  padding: 1.5rem 2rem;

  .dashboardHeader {
    margin: 1.563rem 0 1.25rem 0;
    display: flex;
    justify-content: space-between;

    #refreshButton {
      width: 10rem;
      height: 3rem;
      color: #d0fb56;
      background-color: #03234a;
      border: 0.063rem solid #03234a;
      border-radius: 0.375rem;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-family: "openSansBold";
      font-weight: bold;
      letter-spacing: 0rem;
      text-transform: uppercase;
    }

    .dashboardHeaderText {
      width: 18rem;
      text-align: left;
      font: normal normal 600 1.75rem/2.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #03234a;
    }

    .backButton {
      width: 70%;
      font: normal normal normal 1rem/1.375rem opensansSemiBold;
      letter-spacing: 0rem;
      color: #293d57;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;

      .dashboardLink {
        cursor: pointer;
      }

      .ganttChartLink {
        cursor: pointer;
        margin-left: 2rem;
      }
    }
  }

  .dashboardContentContainer {
    width: 100%;
    height: 58.125rem;
    padding: 1rem 0;
    display: flex;
    background-color: white;


    .orchestrationContainer {
      width: 50%;
      border: 0.063rem solid #5c7b8d;
      border-radius: 0.375rem;
      background-color: #f6f9fa;
      margin-right: 1rem;
      margin-left: 1rem;
      height: 100%;

      .orchestrationContainerHeader {
        height: 7%;
        display: flex;
        ;

        .orchestrationHeaderHeading {
          width: 90%;
          background-color: #5c7b8d;
          font: normal normal bold 1rem/1.375rem opensansBold;
          letter-spacing: 0rem;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .refreshIcon {
          width: 10%;
          background-color: #5c7b8d;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .orchestrationChartContainer {
        height: 95%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        .orchestrationGaugeMeterChart {
          height: 98%;

          .orchestrationGaugeMeterChartforJob {
            height: 47%;
            display: flex;
            justify-content: center;
            align-items: center;

            .chartBox {
              width: 60%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .chartBoxHeader {
                font: normal normal 600 1rem/1.375rem opensansSemiBold;
                letter-spacing: 0rem;
                color: #03234a;
              }
            }

            .jobMonitorBox {
              width: 40%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .jobMonitorButton {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0.5rem;
                width: 80%;

                .customButton {
                  background-color: #293d57;
                  border: none;
                  font: normal normal bold 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: white;
                  width: 100%;
                }
              }

              .jobMonitorStatus {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 80%;

                .jobMonitorStatusItem {
                  width: 100%;
                  font: normal normal bold 1rem/1.375rem opensansSemiBold;
                  letter-spacing: 0rem;
                  color: #293d57;
                  margin-top: 0.4rem;
                  display: flex;

                  .jobMonitorStatusColorRed {
                    background-color: #f80000;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 60%;
                    margin-right: 1rem;
                  }

                  .jobMonitorStatusColorYellow {
                    background-color: #f7ba00;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 50%;
                    margin-right: 1rem;
                  }

                  .jobMonitorStatusColorGreen {
                    background-color: #119f33;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 50%;
                    margin-right: 1rem;
                  }

                  .jobMonitorStatusColorGrey {
                    background-color: grey;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 50%;
                    margin-right: 1rem;
                  }

                  .jobMonitorStatusColorBlue {
                    background-color: #0000ff;
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 50%;
                    margin-right: 1rem;
                  }
                }
              }
            }
          }

          .dwInfoChartBox {
            height: 21%;
            width: 100%;
            border: 0.063rem solid #5c7b8d;
            border-radius: 0.375rem;
            margin: 1rem 0.5rem 0.5rem 0;
            display: flex;
            justify-content: space-evenly;

            .dwInfoChartBoxBlock {
              margin-right: 2rem;

              .dwInfoChartBoxContent {
                padding: 0.3rem;
                font: normal normal bold 1rem/1.375rem opensansSemiBold;
                letter-spacing: 0rem;
              }
            }
          }

          .orchestrationGaugeMeterChartforJtg {
            height: 22%;
            display: flex;

            .jtgChartBox {
              width: 33.3%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

        }

        .orchestrationLineChart {
          height: 50%;
          padding: 0rem 0rem 1rem 0rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .noDataFound {
            font: normal normal normal 1rem/1.375rem opensansSemiBold;
            color: #03234a;
          }
        }
      }
    }

    .qualityStatusContainer {
      width: 50%;
      margin-right: 1rem;

      .qualityStatus {
        height: 55%;
        border: 0.063rem solid #5c7b8d;
        border-radius: 0.375rem;
        background-color: #f6f9fa;
        margin-bottom: 1rem;

        .qualityStatusHeader {
          height: 12.5%;
          display: flex;

          .orchestrationHeaderHeading {
            width: 90%;
            background-color: #5c7b8d;
            font: normal normal bold 1rem/1.375rem opensansBold;
            letter-spacing: 0rem;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .refreshIcon {
            width: 10%;
            background-color: #5c7b8d;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .qualityStatusChartContainer {
          height: 87.5%;
          display: flex;
          justify-content: center;
          align-items: center;

          .dataQualityChartBox {
            height: 100%;
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .dataQualityButton {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 0.5rem;
              width: 80%;

              .customButton {
                background-color: #293d57;
                border: none;
                font: normal normal bold 1rem/1.375rem opensansSemiBold;
                letter-spacing: 0rem;
                color: white;
                width: 100%;
              }
            }

            .dataQualityStatus {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 80%;

              .dataQualityStatusItem {
                width: 100%;
                font: normal normal bold 1rem/1.375rem opensansSemiBold;
                letter-spacing: 0rem;
                color: #293d57;
                margin-top: 0.8rem;
                display: flex;

                .dataQualityStatusColorRed {
                  background-color: #f80000;
                  width: 1.3rem;
                  height: 1.3rem;
                  border-radius: 60%;
                  margin-right: 1rem;
                }

                .dataQualityStatusColorGreen {
                  background-color: #119f33;
                  width: 1.3rem;
                  height: 1.3rem;
                  border-radius: 50%;
                  margin-right: 1rem;
                }

                .dataQualityStatusColorGrey {
                  background-color: #808080;
                  width: 1.3rem;
                  height: 1.3rem;
                  border-radius: 50%;
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }

      .dwInfo {
        height: 45%;
        border: 0.063rem solid #5c7b8d;
        border-radius: 0.375rem;
        background-color: #f6f9fa;

        .dwInfoHeader {
          height: 8.5%;
          background-color: #5c7b8d;
          font: normal normal bold 1rem/1.375rem opensansBold;
          letter-spacing: 0rem;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dwInfoChart {
          height: 51.5%;
          display: flex;
          flex-direction: row;
          gap: 20px;
          margin: 1rem 0.5rem 0 0.5rem;

          .dwInfoChartBox {
            height: 130%;
            width: 100%;
            border: 0.063rem solid #5c7b8d;
            border-radius: 0.375rem;
            margin: 0 0 2rem 0;

            .dwInfoChartBoxContent {
              // padding: 0.3rem;
              // font: normal normal bold 1rem/1.375rem opensansSemiBold;
              // letter-spacing: 0rem;
              padding: 0.3rem;
              font-size: calc(0.4vw + 0.5rem);
              /* Adjust this formula as needed */
              font-weight: 500;
              letter-spacing: 0rem;
              white-space: nowrap;
              /* Prevents line breaks */
            }
          }

          .dwInfoChartContainer {
            display: flex;
            width: 100%;
            justify-content: space-between;
            max-height: 85%;

            .dwInfoChartBox {
              height: 100%;
              width: 100%;
              border: 0.063rem solid #5c7b8d;
              border-radius: 0.375rem;
              margin: 0 0 1rem 0;

              .dwInfoChartBoxContent {
                padding: 0.3rem;
                font: normal normal bold 1rem/1.375rem opensansSemiBold;
                letter-spacing: 0rem;
              }
            }
          }

          .dwInfoChartTip {
            height: 10%;
            font: normal normal bold 1rem/1.375rem opensansSemiBold;
            letter-spacing: 0rem;
          }
        }
      }
    }
  }
}

.popover3D {
  border-radius: 8px !important;
  min-width: 400px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3) !important;
  background-color: #e9e8e8 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 15px !important;

}


.popoverHeader {
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 8px 8px 0 0 !important;
  font-weight: bold !important;
}

.popoverBody {
  color: #333 !important;
  font-size: 0.95rem !important;
  line-height: 1.4 !important;

}

.orchestrationTable {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100% !important;
  max-height: 80%;
  border: 0.063rem solid #5c7b8d;
  border-radius: 0.375rem;


  .jobManagementTableHead {
    height: 2rem;
    background-color: rgba(204, 212, 221, 0.9);
    border-radius: 0.375rem;
  }

  .jobManagementTableRow {
    height: 2rem;
    cursor: pointer;
  }

  .jobManagementTableRowSelected {
    height: 2rem;
    cursor: pointer;
    background-color: #ebfcbe !important;
  }

  .statusContainer {
    display: flex;
    align-items: center;

    .jobMonitorStatusColorGrey {
      background-color: grey;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }

    .jobMonitorStatusColorGreen {
      background-color: #119f33;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }

    .jobMonitorStatusColorBlue {
      background-color: #0000ff;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }

    .jobMonitorStatusColorRed {
      background-color: #f80000;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }

    .jobMonitorStatusColorYellow {
      background-color: #f7ba00;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }
  }

  .jobManagementTableStrip {
    width: 100%;
  }

  .jobManagementTableStrip thead {
    height: 1rem;
    white-space: nowrap;
    background: #ccd4dd 0% 0% no-repeat padding-box;
    border-radius: 0.375rem;
    position: sticky;
    top: 0;
  }

  .jobManagementTableStrip tbody tr:nth-child(odd) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.375rem;
  }

  .jobManagementTableStrip tbody tr:nth-child(even) {
    background-color: rgba(232, 239, 243, 0.4);
  }

  .jobManagementTableStrip thead th {
    font: normal normal 600 1rem/1.375rem OpenSansSemiBold;
    letter-spacing: 0rem;
    color: #03234a;
    background-color: rgba(204, 212, 221, 0.9);
  }

  .jobManagementTableStrip tbody td {
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    letter-spacing: 0rem;
    color: #000000;
    opacity: 1;
  }

  tr.selected-row {
    background-color: lightgrey;
    /* Change the background color for the selected row */
  }

  tr:hover {
    background-color: #f5f5f5;
    /* Change the background color when hovered */
  }
}


.copyIcon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.2s ease;
}

.copyIcon:hover {
  color: #007bff;
}

.copyIcon.clicked {
  animation: bounce 0.3s forwards;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}