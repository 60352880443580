.onboardContainer {
  background-color: #e8eff3;
  height: 100%;
  overflow: auto;

  .setupEnvSkipButton {
    font: normal normal normal 1.12rem/1.5rem "openSanRegular";
    text-decoration: underline;
    cursor: pointer;
    padding: 6.43rem 11.18rem 1.43rem 0rem;
  }

  .onBoardContent {
    padding: 0 9.37rem 4.37rem 9.37rem;
  }

  .setUpEnvData {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.625rem;
    width: 100%;
  }
  .setupEnvCarouselItemWelcomeCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    padding: 12.75rem 20rem 12.56rem 20rem;
    margin: 0;
    height: calc(100vh - 15.625rem);

    .welcomeMessageHead {
      color: #03234a !important;
      font: normal normal bold 3.37rem/4.62rem "OpenSansBold";
      padding: 3.31rem 0rem 0rem 0rem;
    }

    .welcomeMessageContent {
      color: #03234a !important;
      font: normal normal normal 3.37rem/4.62rem "OpenSansRegular";
    }
  }

  .bgWadeWhite {
    height: calc(100vh - 15.625rem);
    background-color: #fff;
  }

  a {
    width: auto;
    padding: 2.81rem;
  }

  .setpEnvLeftText {
    padding: 0;
    margin: 4.12rem 0rem 6.25rem 10.68rem !important;
    font: normal normal 600 2rem/2.68rem "openSansSemiBold";
    color: #03234a !important;
    opacity: 1;
  }

  .setupEnvRightPanel {
    padding: 3.12rem 3.12rem;
  }

  .setupEnvRightPanelHeader {
    padding-bottom: 3.12rem;
    font: normal normal 600 1.87rem/2.5rem "openSansSemiBold";
    color: #03234a !important;
  }
  .setUpRightData {
    height: calc(100vh - 28.125rem);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.625rem;
  }
  .setupEnvRightPanelList {
    display: flex;
    vertical-align: center;
    vertical-align: middle;
    align-items: center;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;

    .setupEnvRightPanelNum {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      background: #d0fb56;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 1rem;
      position: relative;
      font: normal normal bold 1rem/1.37rem "openSansBold";
      color: #03234a !important;
    }

    .setupEnvRightPanelText {
      width: 100%;
      padding: 0;
      color: #03234a !important;
      font: normal normal normal 1.5rem/2.06rem "openSansRegular";
      opacity: 1;
      background: rgb(208 251 86 / 20%);

      div {
        margin: 1.5rem 3.12rem 1.5rem 2rem;
      }
    }
  }

  button[data-bs-target] {
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 50% !important;
    background-color: #abb8c3 !important;
    margin: 0rem 0rem 0rem 0.62rem;
  }

  button[class="active"] {
    width: 2rem !important;
    height: 1rem !important;
    border-radius: 0.5rem !important;
    margin: 0.5rem 0rem 0rem 0.62rem;
    border: solid 0.063rem #03234a !important;
    background-color: #03234a !important;
  }
}
