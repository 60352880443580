.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: skeleton-loading 1.5s infinite;
}

.skeleton-header {
    width: 80%;
    height: 20px;
    margin: 10px auto;
}

.skeleton-cell {
    width: 80%;
    height: 20px;
    margin: 10px auto;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }

    50% {
        background-color: #03234a;
    }

    100% {
        background-color: #e0e0e0;
    }
}