.password-container {
  #labelId {
    color: #03234a;
    white-space: nowrap;
    font: normal normal normal 1rem/1.375rem "OpenSansRegular";
    margin-bottom: 0.75rem;
  }
  .input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: red !important;
    color: fieldtext !important;
  }
  .form-control:focus {
    box-shadow: none;
    outline: none;
  }
  #loginPagePasswordWrongInput {
    width: 25rem;
    height: 3rem;
    background-color: #ffffff !important;
    border-radius: 0.375rem;
    color: #e88181;
    border: 0.063rem solid #e88181;
  }
  #passwordInputImage {
    height: 3rem;
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: 9;
    border-left: 0;
    .innerImage {
      margin: 0.125rem 0rem 0.438rem 0rem;
    }
  }
  .password-input {
    &:focus {
      outline: none !important;
      border: 0.063rem solid #ced4da !important;
    }
  }
  .passwordInput-err {
    border: 0.063rem solid #e88181 !important;
    border-left: none !important;
  }
  .image-container {
    height: 3rem;
    background-color: #fff !important;
    position: absolute !important;
    right: 0;
    z-index: 9;
    border-left: 0;
  }
}

.toolTipMessage .tooltip-inner {
  background-color: #ffffff;
  text-align: left;
  font-family: 1.125rem;
  line-height: 1.25rem;
  font-weight: "openSansregular";
  letter-spacing: 0rem;
  color: #666666;
  border: 0.125rem solid #d0fb56;
  opacity: 1;
  padding: 0.625rem;
}

.InputInfoIcon {
  margin-left: 0.313rem;
}
