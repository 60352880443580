* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#table3> :not(caption)>*>* {
    padding: 0 !important;
}

@mixin dynamic-width($width) {
    width: $width;
    background-color: #293d57 !important;
    color: white !important;
    border: none !important;
}

.custom-pagination-item {
    --bs-pagination-color: #293d57;
    background-color: transparent !important;
    transition: background-color 0.3s ease;
}

.custom-pagination-item:hover {
    background-color: #e0e0e0 !important;
}

.custom-pagination-item.page-item.active {
    --bs-pagination-active-bg: #293d57;
    --bs-pagination-active-border-color: none !important;
    --bs-pagination-active-color: white;
}


.topSectionContainer {
    display: flex;
    flex-direction: row;
    width: auto;
    // margin-left: 95px;
    margin-top: 50px;
    justify-content: center;
    align-items: center;

}

.topSectionContainerViewButtonContainer {
    height: 15%;
    width: 40%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px
}

.topSectionContainerViewButton {
    @include dynamic-width(60%);
}

.middleSectionStyle {
    height: 30vh;
    overflow: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 91.3vw;
}

.selectFieldsContainer {
    height: 100%;
    width: 40%;
    overflow: auto;
    scrollbar-width: none
}

.selectFieldsTableHeader {
    background: #03234a;
    color: white;
    position: sticky;
    top: 0;
    z-index: 10;
    /* Ensure this is an integer without quotes */
}

.selectFieldsTableHeaderContentStyle {
    text-align: center;
    padding-left: 1rem
}

.mainContentContainer {
    max-width: 91.3vw;
    height: 40vh;
    overflow: auto;
    padding: 0;
}

.full-size-scroll-hide {
    height: 100%;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;

}

.full-size-scroll-hide::-webkit-scrollbar {
    display: none;
}

.tableStyleMainContent {
    min-width: max-content;
    position: relative;
}

.tableHeader {
    color: white;
    position: sticky;
    top: 0;
    z-index: 2;
}

.centeredHeaderName {
    width: 100%;
    border-right: 0.5px solid #FFF;
    padding: 1rem;
    text-align: center;

}

.checkboxStyle {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-left: 1rem;
}


.outer-container {
    height: 100%;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
}


.content-area {
    height: 85%;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
}


.outer-container::-webkit-scrollbar,
.content-area::-webkit-scrollbar {
    display: none;
}


.table-header {
    background: #03234a;
    color: white;
    position: sticky;
    top: 0;
    z-index: 10;
}


.table-header th {
    text-align: center;
}

.filter-action-bar {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.apply-filter-button {
    @include dynamic-width(20%);
}

.apply-filter-button:disabled {
    opacity: 0.5;
}

.selectFieldsContentHeader {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-left: 1rem;
}

.noFilterMessage {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}


.dropdown-list-container {
    max-height: 300px;
    overflow: hidden;
    width: 100%;
    background: white;
    border-radius: 4px;
    z-index: 999;
}

.dropdown-scrollable-list {
    max-height: 200px;
    max-width: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.dropdown-item {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.dropdown-item span {
    display: block;
    max-width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-item:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.dropdown-scrollable-list::-webkit-scrollbar {
    width: 6px;
}

.dropdown-scrollable-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.dropdown-scrollable-list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.dropdown-scrollable-list .dropdown-item {
    padding: 10px;
    transition: background-color 0.3s ease;
}

.dropdown-scrollable-list .dropdown-item:hover {
    background-color: #e4e0e0;
    color: black;
}

@mixin dynamic-dropdown() {
    background-color: #293d57 !important;
    color: white !important;
    border: none !important;
    border-radius: 5px;
}

.custom-dropdown {
    @include dynamic-dropdown();

    .dropdown-toggle {
        color: white !important;
        background-color: #293d57 !important;
        border: none !important;
    }

    .dropdown-toggle::after {
        color: white !important;
    }
}

.custom-dropdown:hover {
    background-color: #1a2b3c !important;
    border: 1px solid #1a2b3c !important;
}

.tooltip.show {
    background-color: #eaebec !important;
    color: rgb(0, 0, 0) !important;
    opacity: 1 !important;
    border-radius: 5px;
    border: 1px solid black;
}

.tooltip-inner {
    background-color: #eaebec !important;
    color: rgb(0, 0, 0) !important;
    opacity: 1 !important;
    border-radius: 5px;
}

.tooltip-arrow {
    border-top-color: #293d57 !important;
}


.buttonDownload {
    background-color: #03234a !important;
    border: none !important;
    color: white;
    padding: 12px 20px !important;
    font-size: 16px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    transition: background-color 0.3s ease;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.loader-container {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.spinner {
    margin: 0 auto 15px;
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #03234a;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-text {
    font-size: 16px;
    color: #03234a;
}